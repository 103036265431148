import {
  Supplier,
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables,
  InsertSupplierHistoryMutation,
  InsertSupplierHistoryMutationVariables,
  Supplier_Set_Input,
  SupplierFieldsFragment,
  SupplierFieldsFragmentDoc,
} from 'generated/graphql';
import { MutationTuple } from '@apollo/react-hooks';
import { ExecutionResult } from '@apollo/react-common';

export const supplierType = ['Manufacturer', 'Distributor', 'Wholesaler', 'Retail Store'];
export const supplierStatus = ['In Progress', 'Account opened', 'Account Lost'];
export const supplierPipeline = [
  'To Be Contacted',
  'Supplier Contacted',
  'Supplier Responded',
  'Application Received',
  'Application Submitted',
];

export const supplierTags = [
  'Automotive',
  'DVDs & Video Games',
  'Electronics',
  'Food & Grocery',
  'Health, Beauty & Wellness',
  'Leather',
  'Pet',
  'Sporting Goods',
  'Toys, Games, Hobbies',
  'CBD',
  'Dollar Store',
  'Tools',
  'Sports Nutrition',
  'Vitamins, Herbs, dietary supplements',
  'Do not sell to Amazon sellers',
  'Drop shipping',
  'Home',
  'Office',
  'Cleaning supplies',
  'Accessories and appeal',
  'Pharmacy',
];

export const mapSupplierTagsToColors = {
  success: [40],
  processing: [],
  error: [14],
  default: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  warning: [],
};

export const getSupplierTagColors = (tagId: number): string => {
  let color = 'success';
  Object.keys(mapSupplierTagsToColors).some((key) => {
    const exist = mapSupplierTagsToColors[key as keyof typeof mapSupplierTagsToColors].includes(
      tagId as never,
    );
    if (exist) {
      color = key;
      return true;
    }
    return false;
  });

  return color;
};

export const updateSupplier = async ({
  supplier,
  updatedSupplier,
  userId,
  updateSupplierMutation,
  insertSupplierHistoryMutation,
}: {
  supplier: Supplier;
  updatedSupplier: Supplier_Set_Input;
  userId?: string;
  updateSupplierMutation: MutationTuple<UpdateSupplierMutation, UpdateSupplierMutationVariables>[0];
  insertSupplierHistoryMutation?: MutationTuple<
    InsertSupplierHistoryMutation,
    InsertSupplierHistoryMutationVariables
  >[0];
}): Promise<ExecutionResult<UpdateSupplierMutation>> => {
  const result = await updateSupplierMutation({
    variables: {
      id: supplier.id,
      supplier: {
        ...updatedSupplier,
        user_id: userId,
      },
    },
    optimisticResponse: {
      update_supplier: { affected_rows: 1, __typename: 'supplier_mutation_response' },
    },
    //Optimistic UI
    update: (proxy) => {
      // Read the data from our cache for this query.
      const data = proxy.readFragment<SupplierFieldsFragment>({
        id: `${supplier.id}`,
        fragment: SupplierFieldsFragmentDoc,
      });

      if (!data) {
        return;
      }

      const updSupplier = { ...data, ...updatedSupplier } as Supplier;
      // Write our data back to the cache with the updated data
      proxy.writeFragment<Supplier, SupplierFieldsFragment>({
        id: `${supplier.id}`,
        fragment: SupplierFieldsFragmentDoc,
        data: updSupplier,
      });

      /*const supplierIndex = data?.supplier.findIndex((s) => s.id === supplier.id);
      if (typeof supplierIndex === 'undefined' || supplierIndex === -1 || !data?.supplier) {
        return;
      }

      const updSupplier = { ...data.supplier[supplierIndex], ...updatedSupplier };

      proxy.writeQuery<GetAllSuppliersQuery>({
        query: GetAllSuppliersDocument,
        data: {
          supplier: [
            ...data.supplier.slice(0, supplierIndex),
            updSupplier,
            ...data.supplier.slice(supplierIndex + 1),
          ] as SupplierFieldsFragment[],
        },
      });*/
    },
    refetchQueries: ['getSupplierById'],
  });

  let historyData;
  //add status changes to history
  if (supplier.status !== updatedSupplier.status) {
    historyData = {
      status: updatedSupplier.status,
      statusOld: supplier.status,
    };
  }
  //add pipeline changes to history
  if (supplier.pipeline !== updatedSupplier.pipeline) {
    historyData = {
      pipeline: updatedSupplier.pipeline,
      pipelineOld: supplier.pipeline,
    };
  }

  if (historyData && insertSupplierHistoryMutation) {
    await insertSupplierHistoryMutation({
      variables: {
        history: [
          {
            user_id: userId,
            supplier_id: supplier.id,
            text: historyData,
            type: 3, //status change
          },
        ],
      },
    });
  }

  return result;
};
