/**
 * @see https://github.com/mattxgreen/amazonFBACalc/blob/master/src/index.ts
 */
import { sizeTier } from './sizeTier';
import { amazonFeeReferral } from './amazonFeeReferral';
import { amazonFeeVariableReferral } from './amazonFeeVariableReferral';
import { amazonFeeFulfillment } from './amazonFeeFulfillment';
import { amazonFeeStorage } from './amazonFeeStorage';

export interface AmazonFeeDimension {
  length: number;
  width: number;
  height: number;
}
export interface AmazonFeeDimensionWithWidth extends AmazonFeeDimension {
  weight: number;
}
export const amazonFee = (
  price: number,
  category: string,
  weight: number,
  dimensions?: AmazonFeeDimension,
  size?: string,
) => {
  //const isMedia = category in variableClosingFee;
  if (!size && dimensions) {
    size = sizeTier({ ...dimensions, weight });
  }
  if (!size) {
    console.error('size or dimensions  required to calculate fee');
    return 0;
  }

  //@todo dimWeight = itemWeight(item);
  //const dimWeight = this.getDimensionalWeight(dimensions);

  let feeTotal = 0;
  feeTotal = amazonFeeReferral(price, category);
  feeTotal += amazonFeeVariableReferral(category);
  feeTotal += amazonFeeFulfillment(size, weight);
  feeTotal += amazonFeeStorage(size, weight, dimensions);
  return parseFloat(feeTotal.toFixed(2));
};
