import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { ContentState, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { Card, Button } from 'antd';
import { green } from '@ant-design/colors';
import {
  useInsertSupplierHistoryMutation,
  Supplier_History,
  useUpdateSupplierHistoryMutation,
} from 'generated/graphql';
import { useAuth0 } from 'components/auth/react-auth0-spa';

import 'draft-js-inline-toolbar-plugin/lib/plugin.css';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
});

interface SupplierNoteProps extends Partial<Supplier_History> {
  readOnly?: boolean;
  setEditHistory?: Dispatch<SetStateAction<Supplier_History | undefined>>;
}

export const SupplierNote = (props: SupplierNoteProps) => {
  const { supplier_id, id, text, setEditHistory, readOnly = false } = props;
  const editor = React.useRef(null);
  const { user } = useAuth0();
  const [insertSupplierHistoryMutation] = useInsertSupplierHistoryMutation();
  const [updateSupplierHistoryMutation] = useUpdateSupplierHistoryMutation();
  const [wasFocused, setWasFocused] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (text) {
      // setTimeout fix link plugin
      setTimeout(() => {
        const contentState = convertFromRaw(text);
        const editorStateDef2 = EditorState.createWithContent(contentState);
        setEditorState(editorStateDef2);
      }, 0);
    }
  }, [text]);

  useEffect(() => {
    if (!readOnly && id) {
      setWasFocused(true);
    }
  }, [id, readOnly]);

  const focusEditor = () => {
    if (readOnly) {
      return;
    }
    // @ts-ignore
    editor?.curren?.focus();
    setWasFocused(true);
  };

  const onCancel = () => {
    //clear editor
    const editorStateDefault = EditorState.push(editorState, ContentState.createFromText(''));
    setEditorState(editorStateDefault);
    setEditHistory && setEditHistory(undefined);
    setWasFocused(false);
  };

  const onAddNote = async () => {
    if (!editorState) {
      return;
    }

    // @ts-ignore
    const text = convertToRaw(editorState.getCurrentContent());

    let result;
    if (!id) {
      result = await insertSupplierHistoryMutation({
        refetchQueries: ['getSupplierById'],
        variables: {
          history: [
            {
              user_id: user?.sub,
              supplier_id: supplier_id,
              text: text,
              type: 1,
            },
          ],
        },
      });
    } else {
      result = await updateSupplierHistoryMutation({
        refetchQueries: ['getSupplierById'],
        variables: {
          id,
          supplierHistory: {
            text: text,
            user_id: user?.sub,
          },
        },
      });
    }

    const { errors } = result;

    if (!errors) {
      onCancel();
    } else {
      console.log('errors', errors);
    }
  };

  return (
    <>
      <Card
        size="small"
        bordered={true}
        onClick={focusEditor}
        style={{ marginBottom: 15, backgroundColor: green[0] }}
      >
        <Editor
          readOnly={readOnly}
          ref={editor}
          spellCheck={true}
          plugins={[linkifyPlugin, inlineToolbarPlugin]}
          editorState={editorState}
          onChange={setEditorState}
        />
      </Card>
      <div style={{ textAlign: 'right', display: wasFocused ? 'block' : 'none' }}>
        <Button onClick={onCancel}>Cancel</Button>{' '}
        <Button type="primary" onClick={onAddNote}>
          {id ? 'Edit' : 'Add'} note
        </Button>
      </div>
    </>
  );
};
