import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { User, useUpdateUserMutation } from 'generated/graphql';
import { Store } from 'antd/lib/form/interface';

interface UserFormProps {
  user: User;
}

export const UserForm = (props: UserFormProps) => {
  const { user } = props;
  const [form] = Form.useForm();

  const [updateUserMutation] = useUpdateUserMutation();

  const onFinish = async (values: Store) => {
    const { errors } = await updateUserMutation({
      variables: {
        id: user.id,
        user: values.user,
      },
    });

    if (errors) {
      console.log('errors', errors);
      message.error(errors[0].message);
    } else {
      message.success('The information was updated successfully');
    }
  };

  //from here https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
  React.useEffect(() => {
    form.setFieldsValue({
      user,
    });
  }, [form, user]);

  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 16,
      }}
      form={form}
      name="update-user"
      onFinish={onFinish}
    >
      <Form.Item
        name={['user', 'name']}
        label="Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
