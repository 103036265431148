import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { MatchedRoute } from 'react-router-config';
import { matchBranchToAntRoutes } from 'utils/mapRouteToAntRoutes';

export const AppBreadcrumb = ({ branch }: { branch: MatchedRoute<{}>[] }) => {
  const itemRender = (
    route: Route,
    params: unknown,
    routes: Array<Route>,
    paths: Array<string>,
  ) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <Breadcrumb
      itemRender={itemRender}
      routes={matchBranchToAntRoutes(branch)}
      style={{ margin: '16px 0' }}
    />
  );
};
