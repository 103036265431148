import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useAuth0 } from './auth/react-auth0-spa';
import { renderRoutes, RouteConfigComponentProps, matchRoutes } from 'react-router-config';
import { createApolloClient } from 'utils/createApolloClient';
import { Layout, Avatar, Button, Spin, BackTop, Dropdown, Menu, Badge } from 'antd';
import { UserOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppLeftNav } from './AppLeftNav';
import { routes } from 'routes/createRoutes';
import { ClickParam } from 'antd/lib/menu';

const { Header, Content, Footer } = Layout;

const App = (props: RouteConfigComponentProps) => {
  const { route, history } = props;

  const { loading, logout, isAuthenticated, loginWithRedirect, user, idToken } = useAuth0();

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  const branch = matchRoutes(routes, props.location.pathname);

  return (
    <ApolloProvider client={createApolloClient(idToken, logout)}>
      <Layout style={{ minHeight: '100vh' }}>
        <AppLeftNav history={history} />

        <Layout>
          <Header className="header" style={{ padding: 0 }}>
            {isAuthenticated ? (
              <div style={{ float: 'right', marginRight: 25 }}>
                <Dropdown
                  overlay={
                    <Menu onClick={(param: ClickParam) => history.push(param.key)}>
                      <Menu.Item key="userName" disabled>
                        {user?.name}
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="/dashboard/user/settings">
                        <SettingOutlined />
                        Account Settings
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="/logout" onClick={() => logout()}>
                        <LogoutOutlined /> Logout
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Badge dot status="success" offset={[-35, 50]}>
                    <Avatar
                      icon={<UserOutlined />}
                      src={user?.picture}
                      style={{ cursor: 'pointer' }}
                      size="large"
                    >
                      {user?.name}
                    </Avatar>
                  </Badge>
                </Dropdown>
              </div>
            ) : (
              <div style={{ float: 'right', marginRight: 25 }}>
                <Button
                  size="small"
                  style={{ marginLeft: 16, verticalAlign: 'middle' }}
                  onClick={() => loginWithRedirect({})}
                >
                  Log in
                </Button>
              </div>
            )}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <AppBreadcrumb branch={branch} />

            {renderRoutes(route!.routes)}
          </Content>
          <BackTop />
          <Footer style={{ textAlign: 'center' }}>Yes Distribution ©2020 Created by </Footer>
        </Layout>
      </Layout>
    </ApolloProvider>
  );
};

export default App;
