import React, { useState, ChangeEvent } from 'react';
import Papa from 'papaparse';
import { Modal, Button, Form, Input, Row, Col, Divider, Upload, Dropdown, Menu } from 'antd';
import { EditOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useUpdateSupplierMutation } from 'generated/graphql';
import { Store } from 'antd/lib/form/interface';
import { SupplierXpath } from './types';
import { parseCsv } from '../../../../utils/parseCsv';
import { htmlDecode } from '../../../../utils/htmlDecode';
import { parseJson } from '../../../../utils/parseJson';
import './styles.css';
import { ClickParam } from 'antd/es/menu';
import { downloadFile } from '../../../../utils/downloadFile';

const { TextArea } = Input;

export interface SupplierXpathFormProps {
  xpath?: SupplierXpath;
  supplierId: string;
}

export const SupplierXpathForm = (props: SupplierXpathFormProps) => {
  const { xpath, supplierId } = props;
  const [form] = Form.useForm();
  const [visible, setIsVisible] = useState(false);
  const [updateSupplierMutation] = useUpdateSupplierMutation();

  const onFinish = async (values: Store) => {
    const { xpath } = values;
    xpath.cookies = xpath.cookies?.split('\n');
    xpath.list = xpath.list?.split('\n');
    xpath.functions = xpath.functions ? JSON.parse(xpath.functions) : undefined;

    const result = await updateSupplierMutation({
      refetchQueries: ['getSupplierById'],
      variables: {
        id: supplierId,
        supplier: {
          data: {
            xpath,
          },
        },
      },
    });

    const { errors } = result;

    if (errors) {
      console.log('errors', errors);
    }
    setIsVisible(false);
  };

  //from here https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
  React.useEffect(() => {
    if (visible && xpath) {
      form.setFieldsValue({
        xpath: {
          ...xpath,
          list: xpath.list?.join('\n'),
          cookies: xpath.cookies?.join('\n'),
          functions: JSON.stringify(xpath.functions),
        },
      });
    }
  }, [form, xpath, visible]);

  const onCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleCookiesChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const data = e.target.value.split(',');
    form.setFieldsValue({ xpath: { cookies: data.join('\n') } });
  };
  const handleListChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const data = e.target.value.split(',');
    form.setFieldsValue({ xpath: { list: data.join('\n') } });
  };

  const exportData = (e: ClickParam) => {
    if (!xpath) return;

    let content;
    if (e.key === 'json') {
      content = JSON.stringify(xpath, null, 2);
    }

    if (e.key === 'csv') {
      content = Papa.unparse([xpath]); //, { columns: [] });
    }

    downloadFile(`config.${e.key}`, content);
  };

  return (
    <React.Fragment>
      <Button
        type="link"
        icon={<EditOutlined />}
        style={{ height: 24, padding: 0, marginRight: 10 }}
        onClick={() => setIsVisible(true)}
      >
        Update xpath
      </Button>
      <Modal
        title={'Update xpath'}
        visible={visible}
        onOk={form.submit}
        onCancel={onCancel}
        destroyOnClose={true}
        width={800}
        forceRender
      >
        <Form form={form} name="edit-xpath" layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={['xpath', 'domain']} label="Domain">
                <Input />
              </Form.Item>
              <Form.Item name={['xpath', 'xpath']} label="Category Page: Product Url Xpath">
                <Input />
              </Form.Item>
              <Divider />
              <Form.Item
                name={['xpath', 'xpathCPBlock']}
                label="Category Page: Product Block Xpath"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPTitle']}
                label="Category Page: Product Title Xpath"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPTitle_p2']}
                label="Category Page: Product Title Xpath (part 2)"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPImage']}
                label="Category Page: Product Image Xpath"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPPrice']}
                label="Category Page: Product Price Xpath"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPPrice_reserve']}
                label="Category Page: Product Price Xpath (reserve)"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPUPC']}
                label="Category Page: Product UPC / EAN Xpath"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathCPAvailability']}
                label="Category Page: Product Availability Xpath"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['xpath', 'pageXpath']} label="Category Page: Pagination">
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'pageMultiplication']}
                label="Category Page: Page Multiplication"
              >
                <Input />
              </Form.Item>
              <Divider />
              <Form.Item name={['xpath', 'xpathTitle']} label="Product page: Title Xpath">
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathTitle_p2']}
                label="Product page: Title Xpath (part 2)"
              >
                <Input />
              </Form.Item>
              <Form.Item name={['xpath', 'xpathImg']} label="Product page: Image Xpath">
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathOfferPrice']}
                label="Product page: Offer Price Xpath"
              >
                <Input />
              </Form.Item>
              <Form.Item name={['xpath', 'xpathUPC']} label="Product page: UPC / EAN Xpath">
                <Input />
              </Form.Item>
              <Form.Item
                name={['xpath', 'xpathAvailability']}
                label="Product page: Availability Xpath"
              >
                <Input />
              </Form.Item>
              <div className="ant-row" style={{ marginTop: 34 }}>
                <Upload
                  name="file"
                  accept=".csv,.json"
                  multiple={false}
                  showUploadList={false}
                  customRequest={async ({ onSuccess, file }) => {
                    if (file.type === 'text/csv') {
                      const data: { [key: string]: string }[] = await parseCsv(file);

                      const cleanData: { [key: string]: string } = {};
                      Object.keys(data[0]).forEach((key: string) => {
                        if (typeof data[0][key] === 'string') {
                          cleanData[key] = htmlDecode(data[0][key]);
                        } else {
                          cleanData[key] = data[0][key];
                        }
                      });

                      form.setFieldsValue({ xpath: cleanData });
                    }

                    if (file.type === 'application/json') {
                      const data = await parseJson(file);

                      // @ts-ignore
                      data.functions = JSON.stringify(data.functions);
                      // @ts-ignore
                      data.cookies = data?.cookies?.join('\n');
                      // @ts-ignore
                      data.list = data?.list?.join('\n');

                      form.setFieldsValue({ xpath: data });
                    }

                    onSuccess({}, file);
                  }}
                >
                  <Button type="primary" icon={<DownloadOutlined />}>
                    Import data
                  </Button>
                </Upload>
                <Dropdown
                  overlay={
                    <Menu onClick={exportData}>
                      <Menu.Item key="csv">export CSV</Menu.Item>
                      <Menu.Item key="json">export JSON</Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="primary" icon={<UploadOutlined />} style={{ marginLeft: 8 }}>
                    Export data
                  </Button>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <Form.Item name={['xpath', 'cookies']} label="Cookies">
            <TextArea onChange={handleCookiesChange} autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item name={['xpath', 'list']} label="Url List">
            <TextArea onChange={handleListChange} autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item name={['xpath', 'functions']} label="Functions to apply for parsed data item">
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
