//#Source https://bit.ly/2neWfJ2
export const pluralize = (val?: number, word?: string, plural = word + 's') => {
  const _pluralize = (num: number, word: string, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object')
    return (num: number, word: string) => _pluralize(num, word, val[word]);
  if (!val || !word) {
    return plural;
  }
  return _pluralize(val, word, plural);
};
