import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import { List, Card } from 'antd';
import { SupplierFieldsFragment } from 'generated/graphql';
import { supplierType } from '../utils';
import useWindowDimensions from 'utils/useWindowDimensions';

export const Item = ({ id, name, type }: SupplierFieldsFragment) => {
  const ref = useRef(null);
  const history = useHistory();
  const { width } = useWindowDimensions();

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const description = typeof type !== 'undefined' && type !== null ? supplierType[type] : '';
  return (
    <List.Item
      style={{ padding: '0', marginBottom: 1, display: 'inline', opacity }}
      onClick={() => history.push(`/dashboard/suppliers/${id}`)}
    >
      <div ref={ref}>
        <Card size="small" hoverable>
          <Card.Meta
            style={{ maxWidth: (width - 467) / 5 }} //hack for fix column in Firefox
            title={name}
            description={description}
          />
        </Card>
      </div>
    </List.Item>
  );
};
