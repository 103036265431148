import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, Tag } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import {
  useUpdateSupplierMutation,
  Supplier,
  useInsert_SuppliersMutation,
  useInsertSupplierHistoryMutation,
} from 'generated/graphql';
import {
  supplierType,
  supplierStatus,
  supplierPipeline,
  supplierTags,
  getSupplierTagColors,
  updateSupplier,
} from '../utils';
import { useAuth0 } from 'components/auth/react-auth0-spa';
import { Store } from 'antd/lib/form/interface';

const { Option } = Select;

interface TAddUpdateSupplier {
  afterClose: () => void;
  supplier?: Supplier;
  buttonTitle: string;
  modalTitle: string;
}

export const SupplierForm = (props: TAddUpdateSupplier) => {
  const { afterClose, buttonTitle, modalTitle, supplier } = props;
  const { user } = useAuth0();
  const [form] = Form.useForm();
  const [visible, setIsVisible] = useState(false);
  const [insertSuppliersMutation] = useInsert_SuppliersMutation();
  const [updateSupplierMutation] = useUpdateSupplierMutation();
  const [insertSupplierHistoryMutation] = useInsertSupplierHistoryMutation();

  const onFinish = async (values: Store) => {
    let result;

    if (supplier && supplier.id) {
      result = await updateSupplier({
        supplier,
        updatedSupplier: values.supplier,
        userId: user?.sub,
        updateSupplierMutation,
        insertSupplierHistoryMutation,
      });
    } else {
      result = await insertSuppliersMutation({
        variables: {
          supplier: [
            {
              ...values.supplier,
              supplier_histories: {
                data: {
                  user_id: user?.sub,
                  text: { status: values.supplier.status },
                  type: 3, //status change
                },
              },
            },
          ],
        },
      });
    }

    const { errors } = result;
    if (!errors) {
      setIsVisible(false);
    } else {
      console.log('errors', errors);
    }
  };

  //from here https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
  React.useEffect(() => {
    if (visible && supplier && supplier.id) {
      form.setFieldsValue({
        supplier: {
          //fill form with default values from supplier query
          ...supplier,
          tags: supplier.tags ? supplier.tags : [],
        },
      });
    } else if (visible) {
      //form defaults for new supplier
      form.setFieldsValue({
        supplier: {
          status: 0,
          pipeline: 0,
        },
      });
    }
  }, [form, supplier, visible]);

  const onCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={supplier && supplier.id ? <EditOutlined /> : <PlusOutlined />}
        onClick={() => setIsVisible(true)}
      >
        {buttonTitle}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={form.submit}
        onCancel={onCancel}
        forceRender
        afterClose={afterClose} // update results after modal close
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={form}
          name="add-supplier"
          onFinish={onFinish}
        >
          <Form.Item
            name={['supplier', 'name']}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name={['supplier', 'phone']} label="Phone">
            <Input />
          </Form.Item>
          <Form.Item
            name={['supplier', 'site']}
            label="Website"
            rules={[
              {
                type: 'url',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['supplier', 'email']}
            label="Email"
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={['supplier', 'type']} label="Type">
            <Select>
              {supplierType.map((type, i) => (
                <Option key={i} value={i}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['supplier', 'status']} label="Status">
            <Select>
              {supplierStatus.map((type, i) => (
                <Option key={i} value={i}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['supplier', 'pipeline']} label="Pipeline step">
            <Select>
              {supplierPipeline.map((type, i) => (
                <Option key={i} value={i}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['supplier', 'tags']} label="Tags">
            <Select
              mode="multiple"
              options={supplierTags.map((name, id) => ({ label: name, value: id }))}
              tagRender={(props) => (
                <Tag
                  color={getSupplierTagColors(props.value as number)}
                  closable={props.closable}
                  onClose={props.onClose}
                  style={{ marginRight: 3 }}
                >
                  {props.label}
                </Tag>
              )}
            />
          </Form.Item>
          <Form.Item name={['supplier', 'description']} label="Description">
            <Input.TextArea allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
