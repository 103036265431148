import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { Company, useUpdateCompanyMutation } from 'generated/graphql';
import { Store } from 'antd/lib/form/interface';

interface CompanyFormProps {
  company: Company;
}

export const CompanyForm = (props: CompanyFormProps) => {
  const { company } = props;
  const [form] = Form.useForm();

  const [updateCompanyMutation] = useUpdateCompanyMutation();

  const onFinish = async (values: Store) => {
    const { errors } = await updateCompanyMutation({
      variables: {
        id: company.id,
        company: values.company,
      },
    });

    if (errors) {
      console.log('errors', errors);
      message.error(errors[0].message);
    } else {
      message.success('The information was updated successfully');
    }
  };

  //from here https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
  React.useEffect(() => {
    form.setFieldsValue({
      company,
    });
  }, [form, company]);

  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 16,
      }}
      form={form}
      name="update-company"
      onFinish={onFinish}
    >
      <Form.Item
        name={['company', 'name']}
        label="Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['company', 'email']}
        label="Email"
        rules={[
          {
            type: 'email',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={['company', 'settings', 'seller_id']} label="MWS Seller ID">
        <Input />
      </Form.Item>
      <Form.Item name={['company', 'settings', 'auth_token']} label="MWS Auth Token">
        <Input />
      </Form.Item>
      <Form.Item name={['company', 'description']} label="Description">
        <Input.TextArea allowClear />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
