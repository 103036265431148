import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { UserOutlined, LaptopOutlined } from '@ant-design/icons';
import { ClickParam } from 'antd/lib/menu';
import { History } from 'history';

const { SubMenu } = Menu;
const { Sider } = Layout;

export const AppLeftNav = ({ history }: { history: History }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sider
      collapsed={collapsed}
      onCollapse={setCollapsed}
      collapsible
      className="site-layout-background"
    >
      <div
        className="logo"
        style={{
          height: 65,
          padding: 10,
        }}
      >
        <img
          alt="Yes Distribution logo"
          height="50"
          src="https://yesdist.com/img/logos/logo_yes_2.png"
        />
      </div>
      <Menu
        mode="inline"
        theme="dark"
        //defaultSelectedKeys={['1']}
        //defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
        onClick={(param: ClickParam) => history.push(param.key)}
      >
        <Menu.Item key="/">
          <UserOutlined />
          <span>Home</span>
        </Menu.Item>

        <SubMenu
          key="/dashboard/suppliers"
          title={
            <>
              <LaptopOutlined />
              <span>Suppliers</span>
            </>
          }
        >
          <Menu.Item key="/dashboard/suppliers">List</Menu.Item>
          <Menu.Item key="/dashboard/pipeline">Pipeline</Menu.Item>
        </SubMenu>

        {/*<SubMenu
          key="/dashboard/orders"
          title={
            <>
              <AlignLeftOutlined />
              <span>Orders</span>
            </>
          }
        >
          <Menu.Item key="/dashboard/orders">List</Menu.Item>
        </SubMenu>
        <SubMenu
          key="/dashboard/products"
          title={
            <>
              <BarcodeOutlined />
              <span>Products</span>
            </>
          }
        >
          <Menu.Item key="/dashboard/products">List</Menu.Item>
        </SubMenu>*/}
      </Menu>
    </Sider>
  );
};
