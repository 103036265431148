import { message } from 'antd';

export const parseJson = async (file: File): Promise<{}> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data = event?.target?.result;
      if (!data) {
        resolve({});
      } else {
        try {
          resolve(JSON.parse(data.toString()));
        } catch (e) {
          message.error(`Can not parse JSON file`);
        }
      }
    };

    reader.readAsText(file);

    reader.onerror = (errors) => {
      console.error(errors);
      message.error(`Can not parse JSON file`);
    };
  });
};
