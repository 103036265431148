import { feePercent, feeMin } from './constants';

export const amazonFeeReferral = (price: number, category: string) => {
  let flatFee = 0;
  let percentageFee = 0;
  let referralFee = 0;

  // check if category is in fees
  if (category in feePercent) {
    // @ts-ignore
    flatFee = feeMin[category];
    // @ts-ignore
    percentageFee = feePercent[category] / 100;
  } else {
    flatFee = feeMin['Everything Else'];
    percentageFee = feePercent['Everything Else'] / 100;
  }

  if (percentageFee * price < flatFee) {
    referralFee += flatFee;
  } else {
    referralFee += price * percentageFee;
  }
  return referralFee;
};
