import { Input, AutoComplete } from 'antd';
import React, { useState } from 'react';
import { SupplierFieldsFragment } from 'generated/graphql';
import * as H from 'history';

interface SearchAutocompleteProps {
  supplier?: SupplierFieldsFragment[];
  history: H.History;
}
export const SearchAutocomplete = (props: SearchAutocompleteProps) => {
  const { supplier, history } = props;
  const [searchText, setSearchText] = useState('');

  const searchOptions = supplier
    ?.filter((s) => s.name.toLocaleLowerCase().includes(searchText))
    .map((s) => ({
      value: s.name,
    }));
  return (
    <AutoComplete
      autoFocus={true}
      backfill={true}
      style={{ float: 'right', width: '30%' }}
      onSelect={(selectedName: string) => {
        const redirectTo = supplier?.find(
          (s) => s.name.toLocaleLowerCase() === selectedName.toLocaleLowerCase(),
        );

        if (redirectTo) {
          history.push(`/dashboard/suppliers/${redirectTo.id}`);
        }
      }}
      onSearch={(searchText: string) => setSearchText(searchText.toLocaleLowerCase())}
      options={searchOptions}
    >
      <Input.Search placeholder="Search supplier" />
    </AutoComplete>
  );
};
