import React from 'react';
import { version, Result, Button } from 'antd';
import { Redirect } from 'react-router-dom';

export const Home = () => {
  return (
    <div>
      <Redirect to="/dashboard/suppliers" />
      <h1>antd version: {version}</h1>
      <Result
        status="403"
        title="Page under construction"
        subTitle="We are working on this page."
        extra={<Button type="primary">Home</Button>}
      />
    </div>
  );
};
