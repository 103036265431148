import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

export const createApolloClient = (authToken: string, logout: () => void) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log('[GraphQL error]: Message:', message, 'Location:', locations, 'Path:', path),
      );

    if (networkError) {
      console.log('[Network error]:', networkError);
      if (
        networkError.message ===
        'cannot start as connection_init failed with : Could not verify JWT: JWTExpired'
      ) {
        logout();
      }
    }
  });

  const hasuraUrl = process.env.REACT_APP_HASURA_URL ? process.env.REACT_APP_HASURA_URL : '';
  const wsLink = new WebSocketLink({
    uri: `${hasuraUrl}/v1/graphql`,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, wsLink]),
    cache: new InMemoryCache({
      //cached data based on id
      dataIdFromObject: (object) => object.id,
    }),
  });
};
