import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Spin, Row } from 'antd';
import { ErrorPage } from '../../ErrorPage';
import {
  useGetAllSuppliersQuery,
  useUpdateSupplierMutation,
  Supplier,
  useInsertSupplierHistoryMutation,
} from 'generated/graphql';
import { Column } from './column';
import { supplierPipeline, updateSupplier } from '../utils';
import { useAuth0 } from 'components/auth/react-auth0-spa';
import { SearchAutocomplete } from '../components/SearchAutocomplete';
import { RouteConfigComponentProps } from 'react-router-config';
import { SupplierForm } from '../components/SupplierForm';

export const Pipeline = (props: RouteConfigComponentProps) => {
  const { loading, error, data, refetch } = useGetAllSuppliersQuery();
  const [updateSupplierMutation] = useUpdateSupplierMutation();
  const [insertSupplierHistoryMutation] = useInsertSupplierHistoryMutation();
  const { user } = useAuth0();

  const changePipelineStep = useCallback(
    async (id: string, pipeline: number) => {
      const supplier = data?.supplier.find((s) => s.id === id) as Supplier;
      if (supplier && supplier.pipeline === pipeline) {
        return;
      }

      const { errors } = await updateSupplier({
        supplier,
        updatedSupplier: { pipeline },
        userId: user?.sub,
        updateSupplierMutation,
        insertSupplierHistoryMutation,
      });

      if (errors) {
        console.log('errors', errors);
      }
    },
    [data, insertSupplierHistoryMutation, updateSupplierMutation, user],
  );

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  return (
    <main>
      <div style={{ marginBottom: 16 }}>
        <SupplierForm
          modalTitle="Add a New Supplier"
          buttonTitle="Add Supplier"
          afterClose={refetch}
        />
        <SearchAutocomplete supplier={data?.supplier} history={props.history} />
      </div>

      <DndProvider backend={HTML5Backend}>
        <Row gutter={16}>
          {supplierPipeline.map((pipeline, id) => (
            <Column
              key={id}
              pipelineId={id}
              pipelineName={pipeline}
              suppliers={data?.supplier.filter(
                (item) => item.pipeline === id && item.status === 0, //status in progress
              )}
              changePipelineStep={changePipelineStep}
            />
          ))}
        </Row>
      </DndProvider>
    </main>
  );
};
