import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { parseCsv } from 'utils/parseCsv';
import { createOrderProductsFromTA, ProductFromTA } from '../utils';
import { useInsertProductMutation, useInsertOrderProductMutation } from 'generated/graphql';

const { Dragger } = Upload;

interface SupplierFileUploadProps {
  supplier_id: string;
  order_id: string;
}

export const OrderFileUpload = (props: SupplierFileUploadProps) => {
  const { order_id } = props;
  const [insertProductMutation] = useInsertProductMutation();
  const [insertOrderProductMutation] = useInsertOrderProductMutation();

  return (
    <Dragger
      name="file"
      accept=".csv"
      multiple={false}
      showUploadList={{
        showRemoveIcon: false,
        showPreviewIcon: false,
        showDownloadIcon: false,
      }}
      customRequest={async ({ onSuccess, file }) => {
        const data: ProductFromTA[] = await parseCsv(file, true);
        await createOrderProductsFromTA(
          data,
          order_id,
          insertProductMutation,
          insertOrderProductMutation,
        );
        console.log('data', data);
        onSuccess({}, file);
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a export file from Saved List of Tactical Arbitrage
      </p>
    </Dragger>
  );
};
