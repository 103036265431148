import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RouteConfigComponentProps } from 'react-router-config';

/**
 * https://ant.design/components/result/
 * @param props
 * @constructor
 */
export const ErrorPage = (
  props?: RouteConfigComponentProps<{ message: string }> | { message: string },
) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle={
        // @ts-ignore
        props && props.message ? props.message : 'Sorry, the page you visited does not exist'
      }
      extra={
        <Button type="primary">
          <Link to="/">Back Home</Link>
        </Button>
      }
    />
  );
};
