import React, { createRef } from 'react';
import { Table, Spin, Button, Input, Tag, Alert } from 'antd';
import { green } from '@ant-design/colors';
import { SearchOutlined } from '@ant-design/icons';
import { RouteConfigComponentProps } from 'react-router-config';
import { SupplierForm } from './components/SupplierForm';
import {
  Supplier as TSupplier,
  useGetAllSuppliersQuery,
  SupplierFieldsFragment,
} from 'generated/graphql';
import { DeleteConfirm } from './components/DeleteConfirm';
import {
  supplierType,
  supplierPipeline,
  supplierStatus,
  supplierTags,
  getSupplierTagColors,
} from './utils';
import { Link } from 'react-router-dom';
import { ErrorPage } from '../ErrorPage';
import { SearchAutocomplete } from './components/SearchAutocomplete';

const { Column } = Table;

export const List = (props: RouteConfigComponentProps<{}>) => {
  const { loading, error, data, refetch } = useGetAllSuppliersQuery();
  const searchInput = createRef<Input>();

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }
  if (error) {
    return <ErrorPage message={error.message} />;
  }

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <SupplierForm
          afterClose={refetch}
          modalTitle="Add a New Supplier"
          buttonTitle="Add Supplier"
        />
        <SearchAutocomplete supplier={data?.supplier} history={props.history} />
      </div>
      {/* //we need this Alert to load styles that we use to coloring table rows*/}
      <Alert message="" style={{ display: 'none' }} />
      <Table
        dataSource={data?.supplier}
        size="middle"
        rowClassName={(record: SupplierFieldsFragment) =>
          record.status === 1 ? 'ant-alert-success' : record.status === 2 ? 'ant-alert-error' : ''
        }
        rowKey="id"
      >
        <Column
          title="Name"
          dataIndex="name"
          sorter={(a: TSupplier, b: TSupplier) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)}
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={searchInput}
                placeholder="Search name"
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={confirm}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>

              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? green.primary : undefined }} />
          )}
          onFilter={(value, record: TSupplier) =>
            record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
          }
          onFilterDropdownVisibleChange={(visible) => {
            if (visible) {
              setTimeout(() => searchInput?.current?.select());
            }
          }}
          render={(text, record: TSupplier) => (
            <Link to={`/dashboard/suppliers/${record.id}`}>{record.name}</Link>
          )}
        />
        <Column title="Email" dataIndex="email" />
        <Column title="Phone" dataIndex="phone" />
        <Column
          title="Site"
          dataIndex="site"
          ellipsis
          render={(site) => (site ? <a href={site}>{site}</a> : '')}
        />
        <Column
          title="Tags"
          dataIndex="tags"
          filters={supplierTags.map((name, id) => ({ text: name, value: id }))}
          onFilter={(value, record: TSupplier) => record.tags && record.tags.includes(value)}
          render={(tags) =>
            tags &&
            tags.map((t: number) => (
              <Tag key={t} color={getSupplierTagColors(t)}>
                {supplierTags[t]}
              </Tag>
            ))
          }
        />
        <Column
          title="Type"
          dataIndex="type"
          sorter={true}
          filters={supplierType.map((name, id) => ({ text: name, value: id }))}
          onFilter={(value, record: TSupplier) => record.type === value}
          render={(id) => supplierType[id]}
        />
        <Column
          title="Status"
          dataIndex="status"
          filters={supplierStatus.map((name, id) => ({ text: name, value: id }))}
          onFilter={(value, record: TSupplier) => record.status === value}
          render={(id) => supplierStatus[id]}
        />
        <Column
          title="Pipeline"
          dataIndex="pipeline"
          filters={supplierPipeline.map((name, id) => ({ text: name, value: id }))}
          onFilter={(value, record: TSupplier) => record.pipeline === value}
          render={(id) => supplierPipeline[id]}
        />
        <Column
          title="Created at"
          dataIndex="created_at"
          // @ts-ignore
          sorter={(a: TSupplier, b: TSupplier) => new Date(b.created_at) - new Date(a.created_at)}
          render={(date) => new Date(date).toLocaleDateString('en-US')}
          defaultSortOrder="ascend"
        />

        <Column
          title="Action"
          dataIndex="action"
          render={(text, record: TSupplier) => (
            <DeleteConfirm id={record.id} type="supplier" shape="circle" />
          )}
        />
      </Table>
    </div>
  );
};
