import { ProductSalesEstimateProps } from '../components/ProductSalesEstimate';

//@todo move this to back end, need to set referer header
export const salesEstimateJungleScout = async ({
  category,
  rank,
}: ProductSalesEstimateProps): Promise<string> => {
  /*  curl 'https://api.junglescout.com/api/v1/sales_estimator?rank=344&category=Automotive&store=us' \

                  -H 'referer: https://www.junglescout.com/'*/

  try {
    const response = await fetch(
      `https://cors-anywhere.herokuapp.com/https://api.junglescout.com/api/v1/sales_estimator?rank=${rank}&category=${encodeURIComponent(
        category,
      )}&store=us`,
      {
        headers: {
          referer: 'https://www.junglescout.com/',
        },
        //referer: 'https://www.junglescout.com/',
        // @ts-ignore
        referrer: 'https://www.junglescout.com/',
        referrerPolicy: 'origin',
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.text();
  } catch (e) {
    console.log('salesEstimateAMZTracker error', e);
    return 'error';
  }
};

export const salesEstimateAMZTracker = async ({
  category,
  rank,
}: ProductSalesEstimateProps): Promise<number> => {
  try {
    /**
     * Bypass CORS for production - https://gist.github.com/jesperorb/6ca596217c8dfba237744966c2b5ab1e
     */
    const response = await fetch(
      `https://api.allorigins.win/raw?url=${encodeURIComponent(
        `https://www.amztracker.com/unicorn.php?domain=www.amazon.com&category=${encodeURIComponent(
          category,
        )}&rank=${rank}`,
      )}`,
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return parseInt(await response.text());
  } catch (e) {
    console.log('salesEstimateAMZTracker error', e);
    return 0;
  }
};

export const salesEstimateAMZScout = async ({
  category,
  rank,
}: ProductSalesEstimateProps): Promise<number> => {
  try {
    /**
     * Bypass CORS for production - https://gist.github.com/jesperorb/6ca596217c8dfba237744966c2b5ab1e
     */
    const response = await fetch(
      `https://api.allorigins.win/raw?url=${encodeURIComponent(
        `https://amzscout.net/api/v1/landing/sales?domain=COM&category=${encodeURIComponent(
          category,
        )}&rank=${rank}`,
      )}`,
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const { sales } = await response.json();
    return parseInt(sales);
  } catch (e) {
    console.log('salesEstimateAMZTracker error', e);
    return 0;
  }
};
