import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Auth0Provider } from './components/auth/react-auth0-spa';
import { routes } from './routes/createRoutes';
import './styles/App.css';

const mainRoutes = (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN ? process.env.REACT_APP_AUTH_DOMAIN : ''}
    client_id={process.env.REACT_APP_AUTH_CLIENT_ID ? process.env.REACT_APP_AUTH_CLIENT_ID : ''}
    redirect_uri={window.location.origin}
  >
    <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
  </Auth0Provider>
);

ReactDOM.render(mainRoutes, document.getElementById('root'));
