export const orderStatus = [
  'New',
  'Declined',
  'In progress',
  'Shipped to prep',
  'In prep',
  'Shipped to Amazon',
  'Finished',
];

export const orderProductStatus = ['New', 'Approved', '50/50', 'Declined', 'Out of stock'];
