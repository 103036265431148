import { AmazonFeeDimension } from './amazonFee';

export const amazonFeeStorage = (
  size: string,
  weight: number,
  dimensions?: AmazonFeeDimension,
): number => {
  let storageFee = 0;

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const cubicFeet = dimensions
    ? (dimensions.width / 12) * (dimensions.height / 12) * (dimensions.length / 12)
    : 0.1; // 0.1 by default
  if (currentMonth >= 0 && currentMonth <= 9) {
    storageFee = /Over/i.test(size) ? 0.48 * cubicFeet : 0.69 * cubicFeet;
  } else {
    storageFee = /Over/i.test(size) ? 1.2 * cubicFeet : 2.4 * cubicFeet;
  }
  storageFee = parseFloat(storageFee.toFixed(2));
  // Minimum fee $0.01
  storageFee = storageFee || 0.01;
  return storageFee;
};
