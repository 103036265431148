import { MutationTuple } from '@apollo/react-hooks';
import {
  InsertProductMutation,
  InsertProductMutationVariables,
  InsertOrderProductMutation,
  InsertOrderProductMutationVariables,
  Product_Insert_Input,
  Order_Product_Insert_Input,
  Order_Product,
} from '../../../generated/graphql';
import { message } from 'antd';
import {
  LARGE_STANDARD_SIZE,
  MEDIUM_OVERSIZE_SIZE,
  SMALL_OVERSIZE_SIZE,
  SMALL_STANDARD_SIZE,
  SPECIAL_OVERSIZE_SIZE,
} from '../product/utils/amazonFee/constants';

const PREP_COST = 0.75;

export interface ProductFromTA {
  adjustedPriceAfterPrepAndShip: string;
  amazonBuyBoxPrice: string;
  amazonCategory: string;
  amazonImage: string;
  amazonInStock30Days: string;
  amazonReturnAfterFees: string;
  amazonSellerCentralUrl: string;
  amazonSellsAndInStock: string;
  amazonTitle: string;
  amazonUpcEan: string;
  amazonUrl: string;
  asin: string;
  averagePrice30Days: string;
  averagePrice90Days: string;
  averageRank30Days: string;
  averageRank90Days: string;
  brand: string;
  buyBox: string;
  camelCamelCamelUrl: string;
  competitiveFbaSellers: string;
  discountsAndTaxes: string;
  estimatedMonthlySales: string;
  grossProfit: string;
  grossRoi: string;
  gstVatToRemit: string;
  identifier: string;
  inStock: string;
  kUrl: string;
  lastUpdate: string;
  matchQuality: string;
  newSellers: string;
  note: string;
  parentAsin: string;
  potentialMonthlyProfit: string;
  prepAndShip: string;
  price: string;
  productImage: string;
  productSize: string;
  qty: string;
  rating: string;
  reviews: string;
  salesRank: string;
  source: string;
  sourceUrl: string;
  title: string;
  upcEan: string;
  variations: string;
  weightlbs: string;
}

const matchTASize = (size: string): string => {
  const match = {
    'Large Standard-Size': LARGE_STANDARD_SIZE,
    'Medium Oversize': MEDIUM_OVERSIZE_SIZE,
    'Small Oversize': SMALL_OVERSIZE_SIZE,
    'Small Standard-Size': SMALL_STANDARD_SIZE,
    'Special Oversize': SPECIAL_OVERSIZE_SIZE,
  };

  // @ts-ignore
  return match[size] ? match[size] : LARGE_STANDARD_SIZE;
};

export const createOrderProductsFromTA = async (
  list: ProductFromTA[],
  order_id: string,
  insertProductMutation: MutationTuple<InsertProductMutation, InsertProductMutationVariables>[0],
  insertOrderProductMutation: MutationTuple<
    InsertOrderProductMutation,
    InsertOrderProductMutationVariables
  >[0],
): Promise<void> => {
  const insertedProducts = [];
  for (const productFromTA of list) {
    const insertProduct: Product_Insert_Input = {
      asin: productFromTA.asin,
      name: productFromTA.amazonTitle,
      price: parseFloat(productFromTA.amazonBuyBoxPrice?.replace('$', '')),
      upc: productFromTA.amazonUpcEan,
      amazon_extra_info: {
        amazonCategory: productFromTA.amazonCategory,
        amazonImage: productFromTA.amazonImage,
        amazonInStock30Days: productFromTA.amazonInStock30Days,
        amazonSellsAndInStock: productFromTA.amazonSellsAndInStock,
        averagePrice30Days: productFromTA.averagePrice30Days,
        averagePrice90Days: productFromTA.averagePrice90Days,
        averageRank30Days: productFromTA.averageRank30Days,
        averageRank90Days: productFromTA.averageRank90Days,
        brand: productFromTA.brand,
        competitiveFbaSellers: productFromTA.competitiveFbaSellers,
        amazonReturnAfterFees: productFromTA.amazonReturnAfterFees,
        estimatedMonthlySales: productFromTA.estimatedMonthlySales,
        rating: parseFloat(productFromTA.rating),
        reviews: parseInt(productFromTA.reviews),
        salesRank: parseInt(productFromTA.salesRank),
        weightLbs: parseFloat(productFromTA.weightlbs),
        productSize: matchTASize(productFromTA.productSize),
      },
    };

    insertedProducts.push(insertProduct);
  }

  const { data, errors } = await insertProductMutation({
    variables: {
      product: insertedProducts,
    },
  });

  if (errors || !data || !data.insert_product || !data.insert_product.returning) {
    message.error(`Problem with creating product`);
    return;
  }

  const insertedProductLinkToOrders = [];
  for (const insertedProduct of data.insert_product.returning) {
    const productFromTA = list.find((l) => l.asin === insertedProduct.asin);
    if (!productFromTA) return;

    const insertProductLinkToOrder: Order_Product_Insert_Input = {
      order_id,
      product_id: insertedProduct.id,
      qty: 0,
      buyCost: parseFloat(productFromTA.price?.replace('$', '')),
      prepCost: PREP_COST,
      status: 0, //New
      data: {
        name: productFromTA.title,
        identifier: productFromTA.identifier,
        url: productFromTA.sourceUrl,
        image: productFromTA.productImage,
        upc: productFromTA.upcEan,
      },
    };

    insertedProductLinkToOrders.push(insertProductLinkToOrder);
  }

  const { errors: errors2 } = await insertOrderProductMutation({
    variables: {
      data: insertedProductLinkToOrders,
    },
    refetchQueries: ['getOrderById'],
  });

  if (errors2) {
    message.error(`Problem with creating product link to order`);
    return;
  }
};

export const getOrderSummary = (orderProduct: Order_Product[]) => {
  const summary = orderProduct.filter((data) => data.status === 1); //only approved

  let result = {
    qty: 0,
    buyCost: 0,
    prepCost: 0,
    total: 0,
  };

  if (summary.length === 0) {
    return result;
  }

  summary.forEach((row) => {
    const qty = row.qty ? row.qty : 0;
    result = {
      qty: result.qty + qty,
      buyCost: result.buyCost + row.buyCost * qty,
      prepCost: result.prepCost + row.prepCost * qty,
      total: result.total + (row.buyCost + row.prepCost) * qty,
    };
  });

  return result;
};

export const getPossibleId = (row: Order_Product) => {
  return row.data.identifier || row.data.upc || row.product.upc || row.product.asin;
};
