import { Statistic, Spin } from 'antd';
import { salesEstimateAMZTracker } from '../utils/salesEstimate';
import React, { useEffect, useState } from 'react';
import { realSalesCalculation } from '../utils/realSalesCalculation';

export interface ProductSalesEstimateProps {
  category: string;
  rank: string;
  competitiveFbaSellers?: number;
}
export const ProductSalesEstimate = ({
  category,
  rank,
  competitiveFbaSellers = 0,
}: ProductSalesEstimateProps) => {
  const [salesAMZTracker, setSalesAMZTracker] = useState<number>(-1);
  //const [salesAMZScout, setSalesAMZScout] = useState<number>(-1);
  //const [salesJungleScout, setSalesJungleScout] = useState<number>(-1);

  const fetchData = async () => {
    const salesAMZTracker = await salesEstimateAMZTracker({ category, rank });
    setSalesAMZTracker(realSalesCalculation(salesAMZTracker, competitiveFbaSellers));

    //const salesAMZScout = await salesEstimateAMZScout({ category, rank });
    //setSalesAMZScout(realSalesCalculation(salesAMZScout, competitiveFbaSellers));

    //const salesJungleScout = await salesEstimateJungleScout({ category, rank });
    //setSalesJungleScout(realSalesCalculation(salesJungleScout, competitiveFbaSellers));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {/*<Statistic
        title="JungleScout"
        value={salesJungleScout}
        formatter={(value) => (value === -1 ? <Spin /> : value)}
      />*/}
      {/* <Statistic
        title="AMZ Scout"
        value={salesAMZScout}
        formatter={(value) => (value === -1 ? <Spin /> : value)}
      />*/}
      <Statistic
        title="AMZ Tracker"
        value={salesAMZTracker}
        formatter={(value) => (value === -1 ? <Spin /> : value)}
      />
    </div>
  );
};
