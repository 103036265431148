import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Col, List, Card, Badge } from 'antd';
import { green } from '@ant-design/colors';
import { SupplierFieldsFragment } from 'generated/graphql';
import { Item } from './item';
import { pluralize } from 'utils/pluralize';
import './column.css';

interface ColumnProps {
  pipelineId: number;
  pipelineName: string;
  changePipelineStep: (id: string, pipeline: number) => void;
  suppliers?: SupplierFieldsFragment[];
}

export const Column = ({
  pipelineId,
  pipelineName,
  changePipelineStep,
  suppliers,
}: ColumnProps) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    drop(item) {
      // @ts-ignore
      changePipelineStep(item.id, pipelineId);
    },
  });
  drop(ref);

  return (
    <Col
      style={{
        //5 column style @see https://codesandbox.io/s/unruffled-darkness-54352?file=/index.js:163-230
        flexBasis: '20%',
        width: '20%',
      }}
    >
      <div ref={ref}>
        <List
          bordered
          size="small"
          header={
            <Card bodyStyle={{ padding: 13 }} style={{ backgroundColor: green[0] }}>
              <Card.Meta
                title={pipelineName}
                description={
                  <>
                    <Badge
                      count={suppliers?.length}
                      showZero={true}
                      style={{ backgroundColor: green[3] }}
                    />{' '}
                    {pluralize(suppliers?.length, 'supplier')}
                  </>
                }
              />
            </Card>
          }
          dataSource={suppliers}
          renderItem={(item: SupplierFieldsFragment) => <Item {...item} />}
        />
      </div>
    </Col>
  );
};
