import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, {
  LogoutOptions,
  PopupLoginOptions,
  getIdTokenClaimsOptions,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  RedirectLoginResult,
  IdToken,
  Auth0ClientOptions,
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { Result, Button, Spin } from 'antd';
import { HeartOutlined } from '@ant-design/icons';

interface AuthUser {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
}

interface Auth0Context {
  idToken: string;
  isAuthenticated: boolean;
  user?: AuthUser;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}
interface Auth0ProviderOptions {
  children?: React.ReactElement;
  onRedirectCallback?(result: RedirectLoginResult): void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Auth0Context | null>(null);
export const useAuth0 = () => useContext<Auth0Context | null>(Auth0Context)!;

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthUser>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [idToken, setIdToken] = useState<string>('');

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        const idTokenClaims = await auth0FromHook!.getIdTokenClaims();
        setIdToken(idTokenClaims.__raw);
      }

      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (o: PopupLoginOptions) => {
    setPopupOpen(true);
    try {
      await auth0Client!.loginWithPopup(o);
    } catch (error) {
      console.error(error);
      return;
    }

    setPopupOpen(false);
    const user = await auth0Client!.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    try {
      const result = await auth0Client!.handleRedirectCallback();
      const user = await auth0Client!.getUser();
      const idTokenClaims = await auth0Client!.getIdTokenClaims();
      setIdToken(idTokenClaims.__raw);

      setLoading(false);
      setIsAuthenticated(true);
      setUser(user);
      return result;
    } catch (e) {
      console.log('handleRedirectCallback error', e);
    }

    return {};
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Result
        title="Welcome to the DataSync app, Please login to continue"
        icon={<HeartOutlined />}
        extra={
          <Button type="primary" key="console" onClick={() => auth0Client!.loginWithRedirect({})}>
            Login
          </Button>
        }
      />
    );
  }

  return (
    <Auth0Context.Provider
      value={{
        idToken,
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) =>
          auth0Client!.getIdTokenClaims(o),
        loginWithRedirect: (o: RedirectLoginOptions) => auth0Client!.loginWithRedirect(o),
        getTokenSilently: (o: GetTokenSilentlyOptions | undefined) =>
          auth0Client!.getTokenSilently(o),
        getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) =>
          auth0Client!.getTokenWithPopup(o),
        logout: (o: LogoutOptions | undefined) =>
          auth0Client!.logout({
            ...o,
            returnTo: window.location.origin, //by default redirect back to site
          }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
