import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs } from 'antd';
import { PlusOutlined, DownloadOutlined, StopOutlined } from '@ant-design/icons';
import { useUpdateScanMutation, Scan_Set_Input, Scan } from 'generated/graphql';
import useFetch from 'use-http';
import { scanStatus } from '../constants';

const scanServer = process.env.REACT_APP_CRAWLER_URL ? process.env.REACT_APP_CRAWLER_URL : '';
export interface ScanFormFormProps {
  scan?: Scan;
  supplierId: string;
}

export const ScanForm = (props: ScanFormFormProps) => {
  const { scan } = props;
  const [visible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState({});
  // @ts-ignore
  const { get, response } = useFetch(scanServer, { cachePolicy: 'no-cache' });

  const [updateScanMutation] = useUpdateScanMutation();

  const updateScan = async (data: Scan_Set_Input) => {
    const { errors } = await updateScanMutation({
      refetchQueries: ['getScanBySupplierId'],
      variables: {
        id: scan?.id,
        data,
      },
    });

    if (errors) {
      console.error('updateScanMutation error', errors);
    }
  };

  const killProcess = async () => {
    const result = await get(`/scanKill?processId=${scan?.process_id}`);
    console.log('killProcess', result);
  };
  const getStatus = async () => {
    if (!scan?.process_id) {
      console.log('No scan process_id');
      return;
    }
    const status = await get(`/scanStatus?processId=${scan?.process_id}`);
    if (response.ok && status.status === 'progress' && status.message) {
      setProgress(status.message);
    }
    console.log('getStatus', status);
    //some error happened
    if (response.ok && status.status === 'No data') {
      await updateScan({ status: 2 }); //Finished
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    //In progress
    if (visible && (scan?.status === 1 || scan?.status === 0)) {
      interval = setInterval(() => getStatus(), 1000);
    }
    // @ts-ignore
    if ((!visible || (scan?.status !== 1 && scan?.status !== 0)) && interval) {
      clearInterval(interval);
    }

    return function cleanup() {
      clearInterval(interval);
    };
  }, [scan, visible]);

  const onCancel = () => {
    setIsVisible(false);
  };

  return (
    <React.Fragment>
      <Button
        type="link"
        icon={scan ? '' : <PlusOutlined />}
        style={{ height: 24, padding: 0 }}
        onClick={() => setIsVisible(true)}
      >
        {scan ? scan?.id : 'Start scan'}
      </Button>
      <Modal
        title={`Scan process ${scan?.id} (${scanStatus[scan?.status as number]})`}
        visible={visible}
        onOk={() => setIsVisible(false)}
        onCancel={onCancel}
        destroyOnClose={true}
        width={800}
        forceRender
      >
        <Tabs defaultActiveKey="1" type="card">
          <Tabs.TabPane tab="Progress" key="1">
            <pre>{JSON.stringify(progress, null, 2)}</pre>

            <Button
              type="link"
              target="_blank"
              href={`${scanServer}/download?file=${scan?.id}.log`}
              icon={<DownloadOutlined />}
            >
              Download process log
            </Button>
            <Button
              type="link"
              target="_blank"
              href={`${scanServer}/download?file=${scan?.id}.csv`}
              icon={<DownloadOutlined />}
              style={{ marginLeft: 8 }}
            >
              Download process csv
            </Button>
            <Button
              type="link"
              target="_blank"
              href={`${scanServer}/download?file=${scan?.id}.xml`}
              icon={<DownloadOutlined />}
              style={{ marginLeft: 8 }}
            >
              Download process xml
            </Button>
            <Button
              danger
              type="primary"
              disabled={scan?.status !== 1}
              icon={<StopOutlined />}
              onClick={killProcess}
              style={{ marginLeft: 8 }}
            >
              Kill process
            </Button>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Server Log" key="2">
            <Button
              type="link"
              target="_blank"
              href={`${scanServer}/download?file=server.log`}
              icon={<DownloadOutlined />}
              style={{ marginLeft: 8 }}
            >
              Download server log
            </Button>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </React.Fragment>
  );
};
