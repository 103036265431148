import React from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import App from 'components/App';
import { Home } from 'components/dashboard/home';
import { View } from 'components/dashboard/supplier/view';
import { View as UserView } from 'components/dashboard/user/view';
import { View as OrderView } from 'components/dashboard/order/view';
import { ErrorPage } from 'components/dashboard/ErrorPage';
import { List } from 'components/dashboard/supplier/list';
import { List as ProductList } from 'components/dashboard/product/list';
import { Pipeline } from 'components/dashboard/supplier/pipeline';
import { Redirect } from 'react-router-dom';

export const routes: RouteConfig[] = [
  {
    component: App,
    breadcrumbName: 'Home',
    routes: [
      {
        path: '/',
        exact: true,
        breadcrumbName: 'Home Page',
        component: Home,
      },
      {
        path: '/dashboard/suppliers',
        exact: true,
        breadcrumbName: 'Suppliers',
        component: List,
      },
      {
        path: '/dashboard/pipeline',
        exact: true,
        breadcrumbName: 'Pipeline',
        component: Pipeline,
      },
      {
        //redirect for backward compatibility with https://crm.vendrive.com/dashboard/supplier?s=QrYOoxpJ
        path: '/dashboard/supplier',
        exact: true,
        // eslint-disable-next-line
        component: (props: RouteConfigComponentProps) => (
          <Redirect
            to={`/dashboard/suppliers/${new URLSearchParams(props.location.search).get('s')}`}
          />
        ),
      },
      {
        path: '/dashboard/suppliers/:id',
        exact: true,
        breadcrumbName: 'ViewSupplier',
        component: View,
      },
      {
        path: '/dashboard/order/:id',
        exact: true,
        breadcrumbName: 'Order',
        component: OrderView,
      },
      {
        path: '/dashboard/products',
        exact: true,
        breadcrumbName: 'Products',
        component: ProductList,
      },
      {
        path: '/dashboard/user/settings',
        exact: true,
        breadcrumbName: 'Account Settings',
        component: UserView,
      },
      {
        //not found route
        component: ErrorPage,
      },
    ],
  },
];
