import { RouteConfig, MatchedRoute } from 'react-router-config';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';

export const mapRouteConfigToAntRoutes = (routes?: RouteConfig[]): Route[] => {
  const newRoutes: Route[] = [];

  if (!routes) return newRoutes;

  routes.forEach((r) => {
    const newRoute: Route = {
      path: r.path ? r.path.toString() : '',
      breadcrumbName: r.breadcrumbName,
    };

    if (r.routes) {
      newRoute.children = mapRouteConfigToAntRoutes(r.routes);
    }

    newRoutes.push(newRoute);
  });

  return newRoutes;
};

export const matchBranchToAntRoutes = (branch: MatchedRoute<{}>[]): Route[] => {
  const newRoutes: Route[] = [];

  branch.forEach((r) => {
    newRoutes.push({
      path: r.route.path ? r.route.path.toString() : '',
      breadcrumbName: r.route.breadcrumbName,
    });
  });

  return newRoutes;
};
