import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Tag,
  Spin,
  Table,
  ConfigProvider,
  InputNumber,
  Button,
  Popover,
  Statistic,
  Descriptions,
  Select,
  Badge,
} from 'antd';
import {
  AmazonOutlined,
  IeOutlined,
  AreaChartOutlined,
  ReloadOutlined,
  MessageOutlined,
  FontSizeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { green } from '@ant-design/colors';
import { RouteConfigComponentProps } from 'react-router-config';
import {
  useGetOrderByIdQuery,
  Order,
  Order_Product,
  useUpdateOrderProductMutation,
  Order_Product_Set_Input,
  useUpdateOrderMutation,
  useRoomMessageCountSubscription,
  Product,
} from 'generated/graphql';
import { ErrorPage } from '../ErrorPage';
import { orderStatus, orderProductStatus } from './constants';
import { DeleteConfirm } from '../supplier/components/DeleteConfirm';
import { OrderForm } from './components/OrderForm';
import { OrderFileUpload } from './components/OrderFileUpload';
import { ProductSalesEstimate } from '../product/components/ProductSalesEstimate';
import { amazonFee } from '../product/utils/amazonFee/amazonFee';
import { roiCalculation } from '../product/utils/roiCalculation';
import { amazonFeeReferral } from '../product/utils/amazonFee/amazonFeeReferral';
import { amazonFeeVariableReferral } from '../product/utils/amazonFee/amazonFeeVariableReferral';
import { amazonFeeFulfillment } from '../product/utils/amazonFee/amazonFeeFulfillment';
import { amazonFeeStorage } from '../product/utils/amazonFee/amazonFeeStorage';
import { getOrderSummary, getPossibleId } from './utils';
import { ChatRoom } from '../../shared/chat/ChatRoom';
import Papa from 'papaparse';
import { downloadFile } from '../../../utils/downloadFile';
import { ProductForm } from '../product/components/ProductForm';

const { Column } = Table;

export const View = (props: RouteConfigComponentProps<{ id: string }>) => {
  const [salesEstimatorVisible, setSalesEstimatorVisible] = useState<string>('');
  const [updateOrderProductMutation] = useUpdateOrderProductMutation();
  const [updateOrderMutation] = useUpdateOrderMutation();
  const { data, loading, error, refetch } = useGetOrderByIdQuery({
    variables: {
      id: props.match.params.id,
    },
  });
  const { data: messageCount } = useRoomMessageCountSubscription({
    variables: {
      rooms: data?.order_by_pk?.order_products?.map((o) => `product-${o.product_id}`),
    },
  });

  useEffect(() => {
    if (!data || !data.order_by_pk || !data.order_by_pk.order_products) {
      return;
    }

    const { qty, buyCost, prepCost, total } = getOrderSummary(
      data.order_by_pk.order_products as Order_Product[],
    );

    updateOrderMutation({
      variables: {
        id: props.match.params.id,
        order: {
          data: {
            ...data.order_by_pk.data,
            qty,
            buyCost,
            prepCost,
            total,
          },
        },
      },
    });
  }, [data]);

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (!loading && (!data || data.order_by_pk === null)) {
    return <ErrorPage message={'Order does not exits'} />;
  }

  if (loading || !data || !data.order_by_pk) {
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  const exportOrder = () => {
    const products: object[] = [];
    data.order_by_pk?.order_products
      .filter((p) => p.status === 1) //approved only
      .forEach((p) => {
        products.push({
          id: getPossibleId(p as Order_Product),
          name: p.data.name,
          qty: p.qty,
          price: p.buyCost,
          total: ((p.qty as number) * p.buyCost).toFixed(2),
        });
      });

    downloadFile(`${data.order_by_pk?.name}.csv`, Papa.unparse(products));
  };

  const onUpdate = async (data: Order_Product_Set_Input) => {
    const { errors } = await updateOrderProductMutation({
      refetchQueries: ['getOrderById'],
      variables: {
        order_id: data.order_id,
        product_id: data.product_id,
        data,
      },
    });

    if (errors) {
      console.log('errors', errors);
    }
  };

  const order = data.order_by_pk;
  const dataSource = order!.order_products;

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={order.name}
        // @ts-ignore
        tags={<Tag color="blue">{orderStatus[order.status]}</Tag>}
        subTitle={order.supplier?.name}
        extra={[
          <Button type="primary" icon={<DownloadOutlined />} onClick={exportOrder} key={0}>
            Export Order
          </Button>,
          <OrderForm afterClose={refetch} order={order as Order} key={1} />,
          <DeleteConfirm id={order.id} type="order" key={2} />,
        ]}
      />

      <ConfigProvider
        //if no data render file upload
        renderEmpty={() => (
          <OrderFileUpload supplier_id={order.supplier_id as string} order_id={order.id} />
        )}
      >
        <Table<typeof dataSource[0]>
          dataSource={dataSource}
          size="middle"
          pagination={false}
          rowClassName={(record) =>
            record.status === 1 ? 'ant-alert-success' : record.status === 3 ? 'ant-alert-error' : ''
          }
          summary={(currentData) => {
            const { qty, buyCost, prepCost, total } = getOrderSummary(
              currentData as Order_Product[],
            );

            return (
              <tr>
                <td>{qty}</td>
                <td>${buyCost.toFixed(2)}</td>
                <td>${prepCost.toFixed(2)}</td>
                <td />
                <td />
                <td />
                <td>Total:</td>
                <td>
                  <b>${total.toFixed(2)}</b>
                </td>
              </tr>
            );
          }}
          rowKey="id"
        >
          <Column
            title="QTY"
            dataIndex="qty"
            render={(text, record: Order_Product) => {
              return (
                <InputNumber
                  defaultValue={text}
                  min={0}
                  onChange={(value) =>
                    onUpdate({
                      order_id: record.order_id,
                      product_id: record.product_id,
                      qty: value as number,
                    })
                  }
                />
              );
            }}
          />
          <Column
            title="Buy cost"
            dataIndex="buyCost"
            sorter={(a: Order_Product, b: Order_Product) => a.buyCost - b.buyCost}
            render={(text, record: Order_Product) => {
              return (
                <InputNumber
                  defaultValue={text}
                  min={0}
                  step={0.1}
                  formatter={(value) => `$${value}`}
                  onChange={(value) =>
                    onUpdate({
                      order_id: record.order_id,
                      product_id: record.product_id,
                      buyCost: value,
                    })
                  }
                />
              );
            }}
          />
          <Column
            title="Prep cost"
            dataIndex="prepCost"
            render={(text, record: Order_Product) => {
              return (
                <InputNumber
                  defaultValue={text}
                  min={0}
                  step={0.1}
                  formatter={(value) => `$${value}`}
                  onChange={(value) =>
                    onUpdate({
                      order_id: record.order_id,
                      product_id: record.product_id,
                      prepCost: value,
                    })
                  }
                />
              );
            }}
          />
          <Column
            title="Links"
            render={(text, record: Order_Product) => {
              return (
                <>
                  <Popover
                    placement="top"
                    overlayStyle={{ textAlign: 'center' }}
                    content={
                      <img
                        style={{ height: 200, margin: 'auto' }}
                        src={record.product.amazon_extra_info.amazonImage}
                        alt={record.product.name?.toString()}
                      />
                    }
                    title={record.product.name}
                  >
                    <Button
                      type="primary"
                      href={`https://www.amazon.com/dp/${record.product.asin}`}
                      target="_blank"
                      icon={<AmazonOutlined />}
                      onClick={() => console.log(2)}
                    />
                  </Popover>
                  <Popover
                    placement="top"
                    overlayStyle={{ textAlign: 'center' }}
                    content={
                      record.data.image && (
                        <img
                          style={{ height: 200, margin: 'auto' }}
                          src={record.data.image}
                          alt={record.data.name?.toString()}
                        />
                      )
                    }
                    title={`${record.data.name} (${getPossibleId(record)})`}
                  >
                    <Button
                      type="primary"
                      href={`${record.data.url}`}
                      target="_blank"
                      icon={<IeOutlined />}
                      style={{ marginLeft: 1 }}
                    />
                  </Popover>

                  <Popover
                    placement="top"
                    overlayStyle={{ height: 465, textAlign: 'center' }}
                    content={
                      <img
                        style={{ height: 400, margin: 'auto' }}
                        src={`https://graph.keepa.com/pricehistory.png?type=2&asin=${record.product.asin}&domain=com&width=600&height=400&amazon=1&new=1&used=0&salesrank=1&range=30`}
                        alt={''}
                      />
                    }
                    title={'Keepa'}
                  >
                    <Button
                      type="primary"
                      href={`https://camelcamelcamel.com/product/${record.product.asin}`}
                      icon={<AreaChartOutlined />}
                      target="_blank"
                      style={{ marginLeft: 1 }}
                    />
                  </Popover>

                  <form
                    style={{ display: 'inline' }}
                    method="POST"
                    action="https://tacticalarbitrage.com/visualmagic/?main_action=tactical_edge"
                    target="_blank"
                  >
                    <input type="hidden" name="submit" value="" />
                    <input type="hidden" name="sourcing_from" value="com" />
                    <input type="hidden" name="selling_at" value="com" />
                    <input type="hidden" name="asin" value={record.product.asin as string} />
                    <input type="hidden" name="price" value={record.buyCost + record.prepCost} />
                    <Button
                      title="Open in TA"
                      htmlType="submit"
                      type="primary"
                      icon={<FontSizeOutlined />}
                      style={{ marginLeft: 1, padding: '3px 0' }}
                    />
                  </form>
                </>
              );
            }}
          />
          <Column
            title="Sales month"
            dataIndex={['product', 'amazon_extra_info', 'estimatedMonthlySales']}
            sorter={(a: Order_Product, b: Order_Product) =>
              a.product.amazon_extra_info.estimatedMonthlySales -
              b.product.amazon_extra_info.estimatedMonthlySales
            }
            defaultSortOrder="descend"
            render={(text, record: Order_Product) => (
              <Popover
                placement="top"
                title="Sales estimate"
                onVisibleChange={(visible) =>
                  setSalesEstimatorVisible(visible ? record.product_id : '')
                }
                content={
                  salesEstimatorVisible &&
                  salesEstimatorVisible === record.product_id && (
                    <ProductSalesEstimate
                      category={record.product.amazon_extra_info.amazonCategory}
                      rank={record.product.amazon_extra_info.salesRank}
                      competitiveFbaSellers={record.product.amazon_extra_info.competitiveFbaSellers}
                    />
                  )
                }
              >
                <Button type="link" style={{ textDecoration: 'underline' }}>
                  {text}
                </Button>
              </Popover>
            )}
          />

          <Column
            title="Price"
            dataIndex={['product', 'price']}
            sorter={(a: Order_Product, b: Order_Product) => a.product.price - b.product.price}
            render={(text, record: Order_Product) => (
              <Popover
                placement="top"
                title="Average Price"
                content={
                  <>
                    <Statistic
                      title="Average Price (30 Days)"
                      value={record.product.amazon_extra_info.averagePrice30Days}
                    />
                    <Statistic
                      title="Average Price (90 Days)"
                      value={record.product.amazon_extra_info.averagePrice90Days}
                    />
                  </>
                }
              >
                <Button type="link" style={{ textDecoration: 'underline' }}>
                  <span>{text}</span>
                </Button>
              </Popover>
            )}
          />

          <Column
            title="ROI"
            render={(text, record: Order_Product) => {
              const price = record.product.price;
              const category = record.product.amazon_extra_info.amazonCategory;
              const weight = record.product.amazon_extra_info.weightLbs;
              const size = record.product.amazon_extra_info.productSize;

              const fee = amazonFee(price, category, weight, undefined, size);
              const profitUnit = price - fee - record.buyCost - record.prepCost;
              const roi = roiCalculation(record.buyCost, record.prepCost, price, fee);

              return (
                <Popover
                  placement="top"
                  title="Amazon Fee"
                  content={
                    <Descriptions size="small" column={2} bordered>
                      <Descriptions.Item label="Fee Referral">
                        ${amazonFeeReferral(price, category).toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Fee Variable Referral">
                        ${amazonFeeVariableReferral(category).toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Fee Fulfillment">
                        ${amazonFeeFulfillment(size, weight).toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Fee Storage">
                        ${amazonFeeStorage(size, weight).toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Fee Total">
                        <b>${fee.toFixed(2)}</b>
                      </Descriptions.Item>
                      <Descriptions.Item label="Profit">
                        <b style={{ color: green[4] }}>${profitUnit.toFixed(2)}</b>
                      </Descriptions.Item>
                    </Descriptions>
                  }
                >
                  <Button type="link" style={{ textDecoration: 'underline' }}>
                    <span>{`${roi}%`}</span>
                  </Button>
                </Popover>
              );
            }}
          />

          <Column
            title="Status"
            dataIndex="status"
            filters={orderProductStatus.map((name, id) => ({ text: name, value: id }))}
            onFilter={(value, record: Order_Product) => record.status === value}
            render={(id, record) => (
              <Select
                defaultValue={id}
                style={{ width: 120 }}
                onChange={(value) =>
                  onUpdate({
                    order_id: record.order_id,
                    product_id: record.product_id,
                    status: value,
                  })
                }
              >
                {orderProductStatus.map((type, i) => (
                  <Select.Option key={i} value={i}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
          <Column
            title="Action"
            dataIndex="action"
            render={(text, record: typeof dataSource[0]) => (
              <>
                <Popover
                  trigger="click"
                  placement="left"
                  overlayStyle={{ height: 400, textAlign: 'center' }}
                  content={<ChatRoom id={record.product_id} />}
                >
                  <Badge
                    count={
                      messageCount?.message_count.find(
                        (m) => m.room === `product-${record.product_id}`,
                      )?.count
                    }
                    offset={[-8, 0]}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      title="Messages"
                      style={{ marginRight: 5 }}
                    >
                      <MessageOutlined />
                    </Button>
                  </Badge>
                </Popover>

                <Button
                  type="primary"
                  disabled
                  shape="circle"
                  title="Re fetch data"
                  style={{ marginRight: 5 }}
                >
                  <ReloadOutlined />
                </Button>

                <ProductForm product={record.product as Product} shape="circle" />

                <DeleteConfirm
                  id={{ product_id: record.product_id, order_id: record.order_id }}
                  type="orderProduct"
                  shape="circle"
                />
              </>
            )}
          />
        </Table>
      </ConfigProvider>
    </>
  );
};
