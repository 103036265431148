import React, { useState } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useInsertSupplierHistoryMutation } from 'generated/graphql';
import { useAuth0 } from '../../../auth/react-auth0-spa';

const { Dragger } = Upload;

export interface FileData {
  id: string;
  name: string;
  href: string;
  size: number;
}

interface SupplierFileUploadProps {
  supplier_id: string;
}

export const SupplierFileUpload = ({ supplier_id }: SupplierFileUploadProps) => {
  const { user } = useAuth0();
  const [fileName, setFilename] = useState('');
  const [insertSupplierHistoryMutation] = useInsertSupplierHistoryMutation();
  const onChange = async (info: UploadChangeParam) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      const { errors } = await insertSupplierHistoryMutation({
        refetchQueries: ['getSupplierById'],
        variables: {
          history: [
            {
              user_id: user?.sub,
              supplier_id,
              text: {
                id: info.file.response.id,
                href: info.file.response.href,
                name: info.file.response.name,
                size: info.file.response.size,
              } as FileData,
              type: 2, //file upload
            },
          ],
        },
      });

      if (!errors) {
        //onCancel();
      } else {
        console.log('errors', errors);
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  return (
    <Dragger
      action="https://api.kloudless.com/v1/accounts/373191048/storage/files"
      name="file"
      multiple={false}
      showUploadList={{
        showRemoveIcon: false,
        showPreviewIcon: false,
        showDownloadIcon: false,
      }}
      onChange={onChange}
      headers={{
        Authorization: process.env.REACT_APP_KLOUDLESS_AUTH
          ? process.env.REACT_APP_KLOUDLESS_AUTH
          : '',
        'X-Kloudless-Metadata': JSON.stringify({
          name: fileName,
          parent_id: 'folder_MTA5NTMwMTAyOTgy', //main parent folder
        }),
      }}
      beforeUpload={(file) => {
        setFilename(file.name);
        return true;
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">Support for a single or bulk upload</p>
    </Dragger>
  );
};
