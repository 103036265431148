import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
  useDelete_SuppliersMutation,
  useDeleteSupplierHistoryMutation,
  useDeleteOrderMutation,
  useDeleteOrderProductMutation,
  DeleteOrderProductMutationVariables,
} from 'generated/graphql';
import { useHistory } from 'react-router-dom';
import { ButtonShape } from 'antd/lib/button/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface TDeleteConfirm {
  id: string | DeleteOrderProductMutationVariables;
  type: 'supplier' | 'supplierHistory' | 'order' | 'orderProduct';
  shape?: ButtonShape;
  size?: SizeType;
}

export const DeleteConfirm = ({ id, type, size = 'middle', shape }: TDeleteConfirm) => {
  const history = useHistory();
  const [deleteSupplier] = useDelete_SuppliersMutation();
  const [deleteSupplierHistoryMutation] = useDeleteSupplierHistoryMutation();
  const [deleteOrderMutation] = useDeleteOrderMutation();
  const [deleteOrderProductMutation] = useDeleteOrderProductMutation();

  const onDelete = async () => {
    let result;
    if (type === 'supplier' && typeof id === 'string') {
      result = await deleteSupplier({
        variables: { id },
      });
    } else if (type === 'order' && typeof id === 'string') {
      result = await deleteOrderMutation({
        //refetchQueries: ['getSupplierById'],
        variables: { id },
      });
    } else if (type === 'supplierHistory' && typeof id === 'string') {
      result = await deleteSupplierHistoryMutation({
        refetchQueries: ['getSupplierById'],
        variables: { id },
      });
    } else if (type === 'orderProduct' && typeof id !== 'string') {
      result = await deleteOrderProductMutation({
        refetchQueries: ['getOrderById'],
        variables: id,
      });
    }

    if (!result) {
      return;
    }

    const { errors } = result;

    if (!errors) {
      if (type === 'supplier') {
        history.push('/dashboard/suppliers');
      }
    } else {
      console.log('errors', errors);
    }
  };

  return (
    <Popconfirm title="Are you sure？" onConfirm={onDelete} onCancel={() => false}>
      <Button type="primary" danger size={size} shape={shape} title="Delete">
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
};
