import React, { useState } from 'react';
import { Modal, Button, Form, Select, Input } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import {
  Product,
  useInsertProductMutation,
  useUpdateProductMutation,
  useGetAllSuppliersQuery,
  InsertProductMutation,
} from 'generated/graphql';
import { Store } from 'antd/lib/form/interface';
import { useHistory } from 'react-router-dom';
import { ButtonType, ButtonShape } from 'antd/lib/button/button';
import { productStatus } from '../constants';

const { Option } = Select;

interface ProductFormProps {
  afterClose?: () => void;
  product?: Product;
  shape?: ButtonShape;
  defaultSupplierId?: string;
  buttonType?: ButtonType;
}

export const ProductForm = (props: ProductFormProps) => {
  const { afterClose, product, defaultSupplierId, buttonType, shape } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const [visible, setIsVisible] = useState(false);
  const { data } = useGetAllSuppliersQuery();
  const [insertProductMutation] = useInsertProductMutation();
  const [updateProductMutation] = useUpdateProductMutation();

  const onFinish = async (values: Store) => {
    let result;

    if (product && product.id) {
      result = await updateProductMutation({
        variables: {
          id: product.id,
          product: values.product,
        },
      });
    } else {
      result = await insertProductMutation({
        variables: {
          product: [values.product],
        },
      });
    }

    const { errors, data } = result;

    const dataInsert = (data as InsertProductMutation).insert_product;
    if (dataInsert && dataInsert.returning && dataInsert.returning[0]) {
      history.push(`/dashboard/product/${dataInsert.returning[0].id}`);
    }

    if (!errors) {
      setIsVisible(false);
    } else {
      console.log('errors', errors);
    }
  };

  //from here https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
  React.useEffect(() => {
    if (visible && product && product.id) {
      form.setFieldsValue({ product });
    } else if (visible) {
      //form defaults for new product
      form.setFieldsValue({
        product: {
          status: 0,
          supplier_id: defaultSupplierId,
        },
      });
    }
  }, [form, product, visible]);

  const onCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  return (
    <React.Fragment>
      <Button
        type={buttonType ? buttonType : 'primary'}
        icon={product && product.id ? <EditOutlined /> : <PlusOutlined />}
        shape={shape}
        onClick={() => setIsVisible(true)}
      >
        {shape !== 'circle' ? (product && product.id ? 'Update product' : 'Create Product') : ''}
      </Button>
      <Modal
        title={product && product.id ? 'Update product' : 'Create Product'}
        visible={visible}
        onOk={form.submit}
        onCancel={onCancel}
        forceRender
        afterClose={afterClose} // update results after modal close
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={form}
          name="add-product"
          onFinish={onFinish}
        >
          <Form.Item
            name={['product', 'name']}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item name={['product', 'supplier_id']} label="Supplier">
            <Select>
              {data?.supplier.map((supplier, i) => (
                <Option key={i} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['product', 'status']} label="Status">
            <Select>
              {productStatus.map((type, i) => (
                <Option key={i} value={i}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
