import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "company" */
export type Company = {
   __typename?: 'company';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  settings?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  /** An array relationship */
  users: Array<User>;
  /** An aggregated array relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "company" */
export type CompanySettingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyUsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
   __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
   __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company" */
export type Company_Aggregate_Order_By = {
  avg?: Maybe<Company_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Company_Max_Order_By>;
  min?: Maybe<Company_Min_Order_By>;
  stddev?: Maybe<Company_Stddev_Order_By>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Order_By>;
  sum?: Maybe<Company_Sum_Order_By>;
  var_pop?: Maybe<Company_Var_Pop_Order_By>;
  var_samp?: Maybe<Company_Var_Samp_Order_By>;
  variance?: Maybe<Company_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Append_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "company" */
export type Company_Arr_Rel_Insert_Input = {
  data: Array<Company_Insert_Input>;
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
   __typename?: 'company_avg_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company" */
export type Company_Avg_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Company_Bool_Exp>>>;
  _not?: Maybe<Company_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Company_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  settings?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  users?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint */
  CompanyIdKey = 'company_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Delete_At_Path_Input = {
  settings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Delete_Elem_Input = {
  settings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Delete_Key_Input = {
  settings?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "company" */
export type Company_Inc_Input = {
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  users?: Maybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
   __typename?: 'company_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company" */
export type Company_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
   __typename?: 'company_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company" */
export type Company_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
   __typename?: 'company_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** on conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns: Array<Company_Update_Column>;
  where?: Maybe<Company_Bool_Exp>;
};

/** ordering options when selecting data from "company" */
export type Company_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  settings?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  users_aggregate?: Maybe<User_Aggregate_Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Prepend_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
   __typename?: 'company_stddev_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company" */
export type Company_Stddev_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
   __typename?: 'company_stddev_pop_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company" */
export type Company_Stddev_Pop_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
   __typename?: 'company_stddev_samp_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company" */
export type Company_Stddev_Samp_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
   __typename?: 'company_sum_fields';
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company" */
export type Company_Sum_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
   __typename?: 'company_var_pop_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company" */
export type Company_Var_Pop_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
   __typename?: 'company_var_samp_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company" */
export type Company_Var_Samp_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
   __typename?: 'company_variance_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company" */
export type Company_Variance_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "message" */
export type Message = {
   __typename?: 'message';
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  room: Scalars['String'];
  status?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};


/** columns and relationships of "message" */
export type MessageDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "message" */
export type Message_Aggregate = {
   __typename?: 'message_aggregate';
  aggregate?: Maybe<Message_Aggregate_Fields>;
  nodes: Array<Message>;
};

/** aggregate fields of "message" */
export type Message_Aggregate_Fields = {
   __typename?: 'message_aggregate_fields';
  avg?: Maybe<Message_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Message_Max_Fields>;
  min?: Maybe<Message_Min_Fields>;
  stddev?: Maybe<Message_Stddev_Fields>;
  stddev_pop?: Maybe<Message_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Message_Stddev_Samp_Fields>;
  sum?: Maybe<Message_Sum_Fields>;
  var_pop?: Maybe<Message_Var_Pop_Fields>;
  var_samp?: Maybe<Message_Var_Samp_Fields>;
  variance?: Maybe<Message_Variance_Fields>;
};


/** aggregate fields of "message" */
export type Message_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "message" */
export type Message_Aggregate_Order_By = {
  avg?: Maybe<Message_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Max_Order_By>;
  min?: Maybe<Message_Min_Order_By>;
  stddev?: Maybe<Message_Stddev_Order_By>;
  stddev_pop?: Maybe<Message_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Message_Stddev_Samp_Order_By>;
  sum?: Maybe<Message_Sum_Order_By>;
  var_pop?: Maybe<Message_Var_Pop_Order_By>;
  var_samp?: Maybe<Message_Var_Samp_Order_By>;
  variance?: Maybe<Message_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Message_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "message" */
export type Message_Arr_Rel_Insert_Input = {
  data: Array<Message_Insert_Input>;
  on_conflict?: Maybe<Message_On_Conflict>;
};

/** aggregate avg on columns */
export type Message_Avg_Fields = {
   __typename?: 'message_avg_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "message" */
export type Message_Avg_Order_By = {
  status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "message". All fields are combined with a logical 'AND'. */
export type Message_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Message_Bool_Exp>>>;
  _not?: Maybe<Message_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Message_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  room?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "message" */
export enum Message_Constraint {
  /** unique or primary key constraint */
  MessagePkey = 'message_pkey'
}

/** columns and relationships of "message_count" */
export type Message_Count = {
   __typename?: 'message_count';
  count?: Maybe<Scalars['bigint']>;
  room?: Maybe<Scalars['String']>;
};

/** aggregated selection of "message_count" */
export type Message_Count_Aggregate = {
   __typename?: 'message_count_aggregate';
  aggregate?: Maybe<Message_Count_Aggregate_Fields>;
  nodes: Array<Message_Count>;
};

/** aggregate fields of "message_count" */
export type Message_Count_Aggregate_Fields = {
   __typename?: 'message_count_aggregate_fields';
  avg?: Maybe<Message_Count_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Message_Count_Max_Fields>;
  min?: Maybe<Message_Count_Min_Fields>;
  stddev?: Maybe<Message_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Message_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Message_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Message_Count_Sum_Fields>;
  var_pop?: Maybe<Message_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Message_Count_Var_Samp_Fields>;
  variance?: Maybe<Message_Count_Variance_Fields>;
};


/** aggregate fields of "message_count" */
export type Message_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "message_count" */
export type Message_Count_Aggregate_Order_By = {
  avg?: Maybe<Message_Count_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Count_Max_Order_By>;
  min?: Maybe<Message_Count_Min_Order_By>;
  stddev?: Maybe<Message_Count_Stddev_Order_By>;
  stddev_pop?: Maybe<Message_Count_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Message_Count_Stddev_Samp_Order_By>;
  sum?: Maybe<Message_Count_Sum_Order_By>;
  var_pop?: Maybe<Message_Count_Var_Pop_Order_By>;
  var_samp?: Maybe<Message_Count_Var_Samp_Order_By>;
  variance?: Maybe<Message_Count_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Message_Count_Avg_Fields = {
   __typename?: 'message_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "message_count" */
export type Message_Count_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "message_count". All fields are combined with a logical 'AND'. */
export type Message_Count_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Message_Count_Bool_Exp>>>;
  _not?: Maybe<Message_Count_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Message_Count_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  room?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Message_Count_Max_Fields = {
   __typename?: 'message_count_max_fields';
  count?: Maybe<Scalars['bigint']>;
  room?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "message_count" */
export type Message_Count_Max_Order_By = {
  count?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Count_Min_Fields = {
   __typename?: 'message_count_min_fields';
  count?: Maybe<Scalars['bigint']>;
  room?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "message_count" */
export type Message_Count_Min_Order_By = {
  count?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
};

/** ordering options when selecting data from "message_count" */
export type Message_Count_Order_By = {
  count?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
};

/** select columns of table "message_count" */
export enum Message_Count_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Room = 'room'
}

/** aggregate stddev on columns */
export type Message_Count_Stddev_Fields = {
   __typename?: 'message_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "message_count" */
export type Message_Count_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Message_Count_Stddev_Pop_Fields = {
   __typename?: 'message_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "message_count" */
export type Message_Count_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Message_Count_Stddev_Samp_Fields = {
   __typename?: 'message_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "message_count" */
export type Message_Count_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Message_Count_Sum_Fields = {
   __typename?: 'message_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "message_count" */
export type Message_Count_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Message_Count_Var_Pop_Fields = {
   __typename?: 'message_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "message_count" */
export type Message_Count_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Message_Count_Var_Samp_Fields = {
   __typename?: 'message_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "message_count" */
export type Message_Count_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Message_Count_Variance_Fields = {
   __typename?: 'message_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "message_count" */
export type Message_Count_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Message_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Message_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Message_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "message" */
export type Message_Inc_Input = {
  status?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "message" */
export type Message_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Message_Max_Fields = {
   __typename?: 'message_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "message" */
export type Message_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Min_Fields = {
   __typename?: 'message_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "message" */
export type Message_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "message" */
export type Message_Mutation_Response = {
   __typename?: 'message_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Message>;
};

/** input type for inserting object relation for remote table "message" */
export type Message_Obj_Rel_Insert_Input = {
  data: Message_Insert_Input;
  on_conflict?: Maybe<Message_On_Conflict>;
};

/** on conflict condition type for table "message" */
export type Message_On_Conflict = {
  constraint: Message_Constraint;
  update_columns: Array<Message_Update_Column>;
  where?: Maybe<Message_Bool_Exp>;
};

/** ordering options when selecting data from "message" */
export type Message_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  room?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Message_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "message" */
export enum Message_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Room = 'room',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "message" */
export type Message_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Message_Stddev_Fields = {
   __typename?: 'message_stddev_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "message" */
export type Message_Stddev_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Message_Stddev_Pop_Fields = {
   __typename?: 'message_stddev_pop_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "message" */
export type Message_Stddev_Pop_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Message_Stddev_Samp_Fields = {
   __typename?: 'message_stddev_samp_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "message" */
export type Message_Stddev_Samp_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Message_Sum_Fields = {
   __typename?: 'message_sum_fields';
  status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "message" */
export type Message_Sum_Order_By = {
  status?: Maybe<Order_By>;
};

/** update columns of table "message" */
export enum Message_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Room = 'room',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Message_Var_Pop_Fields = {
   __typename?: 'message_var_pop_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "message" */
export type Message_Var_Pop_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Message_Var_Samp_Fields = {
   __typename?: 'message_var_samp_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "message" */
export type Message_Var_Samp_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Message_Variance_Fields = {
   __typename?: 'message_variance_fields';
  status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "message" */
export type Message_Variance_Order_By = {
  status?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
   __typename?: 'mutation_root';
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete data from the table: "message" */
  delete_message?: Maybe<Message_Mutation_Response>;
  /** delete data from the table: "order" */
  delete_order?: Maybe<Order_Mutation_Response>;
  /** delete data from the table: "order_product" */
  delete_order_product?: Maybe<Order_Product_Mutation_Response>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete data from the table: "scan" */
  delete_scan?: Maybe<Scan_Mutation_Response>;
  /** delete data from the table: "supplier" */
  delete_supplier?: Maybe<Supplier_Mutation_Response>;
  /** delete data from the table: "supplier_history" */
  delete_supplier_history?: Maybe<Supplier_History_Mutation_Response>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "message" */
  insert_message?: Maybe<Message_Mutation_Response>;
  /** insert data into the table: "order" */
  insert_order?: Maybe<Order_Mutation_Response>;
  /** insert data into the table: "order_product" */
  insert_order_product?: Maybe<Order_Product_Mutation_Response>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "scan" */
  insert_scan?: Maybe<Scan_Mutation_Response>;
  /** insert data into the table: "supplier" */
  insert_supplier?: Maybe<Supplier_Mutation_Response>;
  /** insert data into the table: "supplier_history" */
  insert_supplier_history?: Maybe<Supplier_History_Mutation_Response>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update data of the table: "message" */
  update_message?: Maybe<Message_Mutation_Response>;
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>;
  /** update data of the table: "order_product" */
  update_order_product?: Maybe<Order_Product_Mutation_Response>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update data of the table: "scan" */
  update_scan?: Maybe<Scan_Mutation_Response>;
  /** update data of the table: "supplier" */
  update_supplier?: Maybe<Supplier_Mutation_Response>;
  /** update data of the table: "supplier_history" */
  update_supplier_history?: Maybe<Supplier_History_Mutation_Response>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MessageArgs = {
  where: Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_ProductArgs = {
  where: Order_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ScanArgs = {
  where: Scan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupplierArgs = {
  where: Supplier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Supplier_HistoryArgs = {
  where: Supplier_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: Maybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MessageArgs = {
  objects: Array<Message_Insert_Input>;
  on_conflict?: Maybe<Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_ProductArgs = {
  objects: Array<Order_Product_Insert_Input>;
  on_conflict?: Maybe<Order_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScanArgs = {
  objects: Array<Scan_Insert_Input>;
  on_conflict?: Maybe<Scan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupplierArgs = {
  objects: Array<Supplier_Insert_Input>;
  on_conflict?: Maybe<Supplier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Supplier_HistoryArgs = {
  objects: Array<Supplier_History_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _append?: Maybe<Company_Append_Input>;
  _delete_at_path?: Maybe<Company_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Company_Delete_Elem_Input>;
  _delete_key?: Maybe<Company_Delete_Key_Input>;
  _inc?: Maybe<Company_Inc_Input>;
  _prepend?: Maybe<Company_Prepend_Input>;
  _set?: Maybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MessageArgs = {
  _append?: Maybe<Message_Append_Input>;
  _delete_at_path?: Maybe<Message_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Message_Delete_Elem_Input>;
  _delete_key?: Maybe<Message_Delete_Key_Input>;
  _inc?: Maybe<Message_Inc_Input>;
  _prepend?: Maybe<Message_Prepend_Input>;
  _set?: Maybe<Message_Set_Input>;
  where: Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _append?: Maybe<Order_Append_Input>;
  _delete_at_path?: Maybe<Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Delete_Key_Input>;
  _inc?: Maybe<Order_Inc_Input>;
  _prepend?: Maybe<Order_Prepend_Input>;
  _set?: Maybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ProductArgs = {
  _append?: Maybe<Order_Product_Append_Input>;
  _delete_at_path?: Maybe<Order_Product_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Product_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Product_Delete_Key_Input>;
  _inc?: Maybe<Order_Product_Inc_Input>;
  _prepend?: Maybe<Order_Product_Prepend_Input>;
  _set?: Maybe<Order_Product_Set_Input>;
  where: Order_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _append?: Maybe<Product_Append_Input>;
  _delete_at_path?: Maybe<Product_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Delete_Key_Input>;
  _inc?: Maybe<Product_Inc_Input>;
  _prepend?: Maybe<Product_Prepend_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ScanArgs = {
  _append?: Maybe<Scan_Append_Input>;
  _delete_at_path?: Maybe<Scan_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Scan_Delete_Elem_Input>;
  _delete_key?: Maybe<Scan_Delete_Key_Input>;
  _inc?: Maybe<Scan_Inc_Input>;
  _prepend?: Maybe<Scan_Prepend_Input>;
  _set?: Maybe<Scan_Set_Input>;
  where: Scan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupplierArgs = {
  _append?: Maybe<Supplier_Append_Input>;
  _delete_at_path?: Maybe<Supplier_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Supplier_Delete_Elem_Input>;
  _delete_key?: Maybe<Supplier_Delete_Key_Input>;
  _inc?: Maybe<Supplier_Inc_Input>;
  _prepend?: Maybe<Supplier_Prepend_Input>;
  _set?: Maybe<Supplier_Set_Input>;
  where: Supplier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Supplier_HistoryArgs = {
  _append?: Maybe<Supplier_History_Append_Input>;
  _delete_at_path?: Maybe<Supplier_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Supplier_History_Delete_Elem_Input>;
  _delete_key?: Maybe<Supplier_History_Delete_Key_Input>;
  _inc?: Maybe<Supplier_History_Inc_Input>;
  _prepend?: Maybe<Supplier_History_Prepend_Input>;
  _set?: Maybe<Supplier_History_Set_Input>;
  where: Supplier_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "order" */
export type Order = {
   __typename?: 'order';
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_products: Array<Order_Product>;
  /** An aggregated array relationship */
  order_products_aggregate: Order_Product_Aggregate;
  status?: Maybe<Scalars['Int']>;
  /** An object relationship */
  supplier?: Maybe<Supplier>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "order" */
export type OrderDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "order" */
export type OrderOrder_ProductsArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderOrder_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
   __typename?: 'order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
   __typename?: 'order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  avg?: Maybe<Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Max_Order_By>;
  min?: Maybe<Order_Min_Order_By>;
  stddev?: Maybe<Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Sum_Order_By>;
  var_pop?: Maybe<Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Var_Samp_Order_By>;
  variance?: Maybe<Order_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  data: Array<Order_Insert_Input>;
  on_conflict?: Maybe<Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
   __typename?: 'order_avg_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Order_Bool_Exp>>>;
  _not?: Maybe<Order_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Order_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_products?: Maybe<Order_Product_Bool_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Supplier_Bool_Exp>;
  supplier_id?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint */
  OrderIdKey = 'order_id_key',
  /** unique or primary key constraint */
  OrderPkey = 'order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "order" */
export type Order_Inc_Input = {
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_products?: Maybe<Order_Product_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
   __typename?: 'order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
   __typename?: 'order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
   __typename?: 'order_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
  data: Order_Insert_Input;
  on_conflict?: Maybe<Order_On_Conflict>;
};

/** on conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns: Array<Order_Update_Column>;
  where?: Maybe<Order_Bool_Exp>;
};

/** ordering options when selecting data from "order" */
export type Order_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_products_aggregate?: Maybe<Order_Product_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  supplier?: Maybe<Supplier_Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "order_product" */
export type Order_Product = {
   __typename?: 'order_product';
  buyCost?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  order: Order;
  order_id: Scalars['String'];
  prepCost?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product: Product;
  product_id: Scalars['String'];
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "order_product" */
export type Order_ProductDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_product" */
export type Order_Product_Aggregate = {
   __typename?: 'order_product_aggregate';
  aggregate?: Maybe<Order_Product_Aggregate_Fields>;
  nodes: Array<Order_Product>;
};

/** aggregate fields of "order_product" */
export type Order_Product_Aggregate_Fields = {
   __typename?: 'order_product_aggregate_fields';
  avg?: Maybe<Order_Product_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Order_Product_Max_Fields>;
  min?: Maybe<Order_Product_Min_Fields>;
  stddev?: Maybe<Order_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Product_Sum_Fields>;
  var_pop?: Maybe<Order_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Product_Var_Samp_Fields>;
  variance?: Maybe<Order_Product_Variance_Fields>;
};


/** aggregate fields of "order_product" */
export type Order_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_product" */
export type Order_Product_Aggregate_Order_By = {
  avg?: Maybe<Order_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Product_Max_Order_By>;
  min?: Maybe<Order_Product_Min_Order_By>;
  stddev?: Maybe<Order_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Product_Sum_Order_By>;
  var_pop?: Maybe<Order_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Product_Var_Samp_Order_By>;
  variance?: Maybe<Order_Product_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order_product" */
export type Order_Product_Arr_Rel_Insert_Input = {
  data: Array<Order_Product_Insert_Input>;
  on_conflict?: Maybe<Order_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Product_Avg_Fields = {
   __typename?: 'order_product_avg_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_product" */
export type Order_Product_Avg_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_product". All fields are combined with a logical 'AND'. */
export type Order_Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Order_Product_Bool_Exp>>>;
  _not?: Maybe<Order_Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Order_Product_Bool_Exp>>>;
  buyCost?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  order?: Maybe<Order_Bool_Exp>;
  order_id?: Maybe<String_Comparison_Exp>;
  prepCost?: Maybe<Numeric_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<String_Comparison_Exp>;
  qty?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_product" */
export enum Order_Product_Constraint {
  /** unique or primary key constraint */
  SupplierProductPkey = 'supplier_product_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Product_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Product_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Product_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "order_product" */
export type Order_Product_Inc_Input = {
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_product" */
export type Order_Product_Insert_Input = {
  buyCost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  order?: Maybe<Order_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['String']>;
  prepCost?: Maybe<Scalars['numeric']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Order_Product_Max_Fields = {
   __typename?: 'order_product_max_fields';
  buyCost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  order_id?: Maybe<Scalars['String']>;
  prepCost?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_product" */
export type Order_Product_Max_Order_By = {
  buyCost?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Product_Min_Fields = {
   __typename?: 'order_product_min_fields';
  buyCost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  order_id?: Maybe<Scalars['String']>;
  prepCost?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "order_product" */
export type Order_Product_Min_Order_By = {
  buyCost?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_product" */
export type Order_Product_Mutation_Response = {
   __typename?: 'order_product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Order_Product>;
};

/** input type for inserting object relation for remote table "order_product" */
export type Order_Product_Obj_Rel_Insert_Input = {
  data: Order_Product_Insert_Input;
  on_conflict?: Maybe<Order_Product_On_Conflict>;
};

/** on conflict condition type for table "order_product" */
export type Order_Product_On_Conflict = {
  constraint: Order_Product_Constraint;
  update_columns: Array<Order_Product_Update_Column>;
  where?: Maybe<Order_Product_Bool_Exp>;
};

/** ordering options when selecting data from "order_product" */
export type Order_Product_Order_By = {
  buyCost?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  order?: Maybe<Order_Order_By>;
  order_id?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Product_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "order_product" */
export enum Order_Product_Select_Column {
  /** column name */
  BuyCost = 'buyCost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PrepCost = 'prepCost',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "order_product" */
export type Order_Product_Set_Input = {
  buyCost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  order_id?: Maybe<Scalars['String']>;
  prepCost?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Order_Product_Stddev_Fields = {
   __typename?: 'order_product_stddev_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_product" */
export type Order_Product_Stddev_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Product_Stddev_Pop_Fields = {
   __typename?: 'order_product_stddev_pop_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_product" */
export type Order_Product_Stddev_Pop_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Product_Stddev_Samp_Fields = {
   __typename?: 'order_product_stddev_samp_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_product" */
export type Order_Product_Stddev_Samp_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Product_Sum_Fields = {
   __typename?: 'order_product_sum_fields';
  buyCost?: Maybe<Scalars['numeric']>;
  prepCost?: Maybe<Scalars['numeric']>;
  qty?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_product" */
export type Order_Product_Sum_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** update columns of table "order_product" */
export enum Order_Product_Update_Column {
  /** column name */
  BuyCost = 'buyCost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PrepCost = 'prepCost',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Qty = 'qty',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Order_Product_Var_Pop_Fields = {
   __typename?: 'order_product_var_pop_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_product" */
export type Order_Product_Var_Pop_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Product_Var_Samp_Fields = {
   __typename?: 'order_product_var_samp_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_product" */
export type Order_Product_Var_Samp_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Product_Variance_Fields = {
   __typename?: 'order_product_variance_fields';
  buyCost?: Maybe<Scalars['Float']>;
  prepCost?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_product" */
export type Order_Product_Variance_Order_By = {
  buyCost?: Maybe<Order_By>;
  prepCost?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
   __typename?: 'order_stddev_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
   __typename?: 'order_stddev_pop_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
   __typename?: 'order_stddev_samp_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
   __typename?: 'order_sum_fields';
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
   __typename?: 'order_var_pop_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
   __typename?: 'order_var_samp_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
   __typename?: 'order_variance_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
   __typename?: 'product';
  amazon_extra_info?: Maybe<Scalars['jsonb']>;
  asin?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  ebay_extra_info?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  last_update_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_products: Array<Order_Product>;
  /** An aggregated array relationship */
  order_products_aggregate: Order_Product_Aggregate;
  price?: Maybe<Scalars['numeric']>;
  pricemax?: Maybe<Scalars['numeric']>;
  pricemin?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  upc?: Maybe<Scalars['String']>;
};


/** columns and relationships of "product" */
export type ProductAmazon_Extra_InfoArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "product" */
export type ProductEbay_Extra_InfoArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "product" */
export type ProductOrder_ProductsArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductOrder_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
   __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
   __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: Maybe<Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Max_Order_By>;
  min?: Maybe<Product_Min_Order_By>;
  stddev?: Maybe<Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Sum_Order_By>;
  var_pop?: Maybe<Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Append_Input = {
  amazon_extra_info?: Maybe<Scalars['jsonb']>;
  ebay_extra_info?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
   __typename?: 'product_avg_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Product_Bool_Exp>>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Product_Bool_Exp>>>;
  amazon_extra_info?: Maybe<Jsonb_Comparison_Exp>;
  asin?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  ebay_extra_info?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_update_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  order_products?: Maybe<Order_Product_Bool_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  pricemax?: Maybe<Numeric_Comparison_Exp>;
  pricemin?: Maybe<Numeric_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  upc?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  ProductIdKey = 'product_id_key',
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Delete_At_Path_Input = {
  amazon_extra_info?: Maybe<Array<Maybe<Scalars['String']>>>;
  ebay_extra_info?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Delete_Elem_Input = {
  amazon_extra_info?: Maybe<Scalars['Int']>;
  ebay_extra_info?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Delete_Key_Input = {
  amazon_extra_info?: Maybe<Scalars['String']>;
  ebay_extra_info?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "product" */
export type Product_Inc_Input = {
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  amazon_extra_info?: Maybe<Scalars['jsonb']>;
  asin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ebay_extra_info?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  last_update_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order_products?: Maybe<Order_Product_Arr_Rel_Insert_Input>;
  price?: Maybe<Scalars['numeric']>;
  pricemax?: Maybe<Scalars['numeric']>;
  pricemin?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  upc?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
   __typename?: 'product_max_fields';
  asin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_update_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  pricemax?: Maybe<Scalars['numeric']>;
  pricemin?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  upc?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  asin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  upc?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
   __typename?: 'product_min_fields';
  asin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_update_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  pricemax?: Maybe<Scalars['numeric']>;
  pricemin?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  upc?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  asin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  upc?: Maybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
   __typename?: 'product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** ordering options when selecting data from "product" */
export type Product_Order_By = {
  amazon_extra_info?: Maybe<Order_By>;
  asin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  ebay_extra_info?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order_products_aggregate?: Maybe<Order_Product_Aggregate_Order_By>;
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  upc?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Prepend_Input = {
  amazon_extra_info?: Maybe<Scalars['jsonb']>;
  ebay_extra_info?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  AmazonExtraInfo = 'amazon_extra_info',
  /** column name */
  Asin = 'asin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EbayExtraInfo = 'ebay_extra_info',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdateAt = 'last_update_at',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Price = 'price',
  /** column name */
  Pricemax = 'pricemax',
  /** column name */
  Pricemin = 'pricemin',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  Upc = 'upc'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  amazon_extra_info?: Maybe<Scalars['jsonb']>;
  asin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ebay_extra_info?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  last_update_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  pricemax?: Maybe<Scalars['numeric']>;
  pricemin?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  upc?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
   __typename?: 'product_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
   __typename?: 'product_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
   __typename?: 'product_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
   __typename?: 'product_sum_fields';
  price?: Maybe<Scalars['numeric']>;
  pricemax?: Maybe<Scalars['numeric']>;
  pricemin?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  AmazonExtraInfo = 'amazon_extra_info',
  /** column name */
  Asin = 'asin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EbayExtraInfo = 'ebay_extra_info',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdateAt = 'last_update_at',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Price = 'price',
  /** column name */
  Pricemax = 'pricemax',
  /** column name */
  Pricemin = 'pricemin',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  Upc = 'upc'
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
   __typename?: 'product_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
   __typename?: 'product_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
   __typename?: 'product_variance_fields';
  price?: Maybe<Scalars['Float']>;
  pricemax?: Maybe<Scalars['Float']>;
  pricemin?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  price?: Maybe<Order_By>;
  pricemax?: Maybe<Order_By>;
  pricemin?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
   __typename?: 'query_root';
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "message" */
  message: Array<Message>;
  /** fetch aggregated fields from the table: "message" */
  message_aggregate: Message_Aggregate;
  /** fetch data from the table: "message" using primary key columns */
  message_by_pk?: Maybe<Message>;
  /** fetch data from the table: "message_count" */
  message_count: Array<Message_Count>;
  /** fetch aggregated fields from the table: "message_count" */
  message_count_aggregate: Message_Count_Aggregate;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_product" */
  order_product: Array<Order_Product>;
  /** fetch aggregated fields from the table: "order_product" */
  order_product_aggregate: Order_Product_Aggregate;
  /** fetch data from the table: "order_product" using primary key columns */
  order_product_by_pk?: Maybe<Order_Product>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "scan" */
  scan: Array<Scan>;
  /** fetch aggregated fields from the table: "scan" */
  scan_aggregate: Scan_Aggregate;
  /** fetch data from the table: "scan" using primary key columns */
  scan_by_pk?: Maybe<Scan>;
  /** fetch data from the table: "supplier" */
  supplier: Array<Supplier>;
  /** fetch aggregated fields from the table: "supplier" */
  supplier_aggregate: Supplier_Aggregate;
  /** fetch data from the table: "supplier" using primary key columns */
  supplier_by_pk?: Maybe<Supplier>;
  /** fetch data from the table: "supplier_history" */
  supplier_history: Array<Supplier_History>;
  /** fetch aggregated fields from the table: "supplier_history" */
  supplier_history_aggregate: Supplier_History_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


/** query root */
export type Query_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


/** query root */
export type Query_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


/** query root */
export type Query_RootMessageArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};


/** query root */
export type Query_RootMessage_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};


/** query root */
export type Query_RootMessage_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootMessage_CountArgs = {
  distinct_on?: Maybe<Array<Message_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Count_Order_By>>;
  where?: Maybe<Message_Count_Bool_Exp>;
};


/** query root */
export type Query_RootMessage_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Count_Order_By>>;
  where?: Maybe<Message_Count_Bool_Exp>;
};


/** query root */
export type Query_RootOrderArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootOrder_ProductArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Product_By_PkArgs = {
  order_id: Scalars['String'];
  product_id: Scalars['String'];
};


/** query root */
export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootScanArgs = {
  distinct_on?: Maybe<Array<Scan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scan_Order_By>>;
  where?: Maybe<Scan_Bool_Exp>;
};


/** query root */
export type Query_RootScan_AggregateArgs = {
  distinct_on?: Maybe<Array<Scan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scan_Order_By>>;
  where?: Maybe<Scan_Bool_Exp>;
};


/** query root */
export type Query_RootScan_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootSupplierArgs = {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
};


/** query root */
export type Query_RootSupplier_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
};


/** query root */
export type Query_RootSupplier_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootSupplier_HistoryArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** query root */
export type Query_RootSupplier_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "scan" */
export type Scan = {
   __typename?: 'scan';
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  /** An object relationship */
  supplier?: Maybe<Supplier>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "scan" */
export type ScanDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "scan" */
export type Scan_Aggregate = {
   __typename?: 'scan_aggregate';
  aggregate?: Maybe<Scan_Aggregate_Fields>;
  nodes: Array<Scan>;
};

/** aggregate fields of "scan" */
export type Scan_Aggregate_Fields = {
   __typename?: 'scan_aggregate_fields';
  avg?: Maybe<Scan_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Scan_Max_Fields>;
  min?: Maybe<Scan_Min_Fields>;
  stddev?: Maybe<Scan_Stddev_Fields>;
  stddev_pop?: Maybe<Scan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scan_Stddev_Samp_Fields>;
  sum?: Maybe<Scan_Sum_Fields>;
  var_pop?: Maybe<Scan_Var_Pop_Fields>;
  var_samp?: Maybe<Scan_Var_Samp_Fields>;
  variance?: Maybe<Scan_Variance_Fields>;
};


/** aggregate fields of "scan" */
export type Scan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Scan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "scan" */
export type Scan_Aggregate_Order_By = {
  avg?: Maybe<Scan_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Scan_Max_Order_By>;
  min?: Maybe<Scan_Min_Order_By>;
  stddev?: Maybe<Scan_Stddev_Order_By>;
  stddev_pop?: Maybe<Scan_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Scan_Stddev_Samp_Order_By>;
  sum?: Maybe<Scan_Sum_Order_By>;
  var_pop?: Maybe<Scan_Var_Pop_Order_By>;
  var_samp?: Maybe<Scan_Var_Samp_Order_By>;
  variance?: Maybe<Scan_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scan_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "scan" */
export type Scan_Arr_Rel_Insert_Input = {
  data: Array<Scan_Insert_Input>;
  on_conflict?: Maybe<Scan_On_Conflict>;
};

/** aggregate avg on columns */
export type Scan_Avg_Fields = {
   __typename?: 'scan_avg_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "scan" */
export type Scan_Avg_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scan". All fields are combined with a logical 'AND'. */
export type Scan_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Scan_Bool_Exp>>>;
  _not?: Maybe<Scan_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Scan_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  process_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Supplier_Bool_Exp>;
  supplier_id?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "scan" */
export enum Scan_Constraint {
  /** unique or primary key constraint */
  ScanPkey = 'scan_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scan_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scan_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scan_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "scan" */
export type Scan_Inc_Input = {
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "scan" */
export type Scan_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Scan_Max_Fields = {
   __typename?: 'scan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "scan" */
export type Scan_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Scan_Min_Fields = {
   __typename?: 'scan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "scan" */
export type Scan_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "scan" */
export type Scan_Mutation_Response = {
   __typename?: 'scan_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Scan>;
};

/** input type for inserting object relation for remote table "scan" */
export type Scan_Obj_Rel_Insert_Input = {
  data: Scan_Insert_Input;
  on_conflict?: Maybe<Scan_On_Conflict>;
};

/** on conflict condition type for table "scan" */
export type Scan_On_Conflict = {
  constraint: Scan_Constraint;
  update_columns: Array<Scan_Update_Column>;
  where?: Maybe<Scan_Bool_Exp>;
};

/** ordering options when selecting data from "scan" */
export type Scan_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier?: Maybe<Supplier_Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scan_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "scan" */
export enum Scan_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "scan" */
export type Scan_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Scan_Stddev_Fields = {
   __typename?: 'scan_stddev_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "scan" */
export type Scan_Stddev_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scan_Stddev_Pop_Fields = {
   __typename?: 'scan_stddev_pop_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "scan" */
export type Scan_Stddev_Pop_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scan_Stddev_Samp_Fields = {
   __typename?: 'scan_stddev_samp_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "scan" */
export type Scan_Stddev_Samp_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Scan_Sum_Fields = {
   __typename?: 'scan_sum_fields';
  process_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "scan" */
export type Scan_Sum_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** update columns of table "scan" */
export enum Scan_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  Status = 'status',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Scan_Var_Pop_Fields = {
   __typename?: 'scan_var_pop_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "scan" */
export type Scan_Var_Pop_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scan_Var_Samp_Fields = {
   __typename?: 'scan_var_samp_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "scan" */
export type Scan_Var_Samp_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Scan_Variance_Fields = {
   __typename?: 'scan_variance_fields';
  process_id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "scan" */
export type Scan_Variance_Order_By = {
  process_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
   __typename?: 'subscription_root';
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "message" */
  message: Array<Message>;
  /** fetch aggregated fields from the table: "message" */
  message_aggregate: Message_Aggregate;
  /** fetch data from the table: "message" using primary key columns */
  message_by_pk?: Maybe<Message>;
  /** fetch data from the table: "message_count" */
  message_count: Array<Message_Count>;
  /** fetch aggregated fields from the table: "message_count" */
  message_count_aggregate: Message_Count_Aggregate;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_product" */
  order_product: Array<Order_Product>;
  /** fetch aggregated fields from the table: "order_product" */
  order_product_aggregate: Order_Product_Aggregate;
  /** fetch data from the table: "order_product" using primary key columns */
  order_product_by_pk?: Maybe<Order_Product>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "scan" */
  scan: Array<Scan>;
  /** fetch aggregated fields from the table: "scan" */
  scan_aggregate: Scan_Aggregate;
  /** fetch data from the table: "scan" using primary key columns */
  scan_by_pk?: Maybe<Scan>;
  /** fetch data from the table: "supplier" */
  supplier: Array<Supplier>;
  /** fetch aggregated fields from the table: "supplier" */
  supplier_aggregate: Supplier_Aggregate;
  /** fetch data from the table: "supplier" using primary key columns */
  supplier_by_pk?: Maybe<Supplier>;
  /** fetch data from the table: "supplier_history" */
  supplier_history: Array<Supplier_History>;
  /** fetch aggregated fields from the table: "supplier_history" */
  supplier_history_aggregate: Supplier_History_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


/** subscription root */
export type Subscription_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMessageArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMessage_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMessage_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootMessage_CountArgs = {
  distinct_on?: Maybe<Array<Message_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Count_Order_By>>;
  where?: Maybe<Message_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMessage_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Count_Order_By>>;
  where?: Maybe<Message_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrderArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootOrder_ProductArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Product_Order_By>>;
  where?: Maybe<Order_Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Product_By_PkArgs = {
  order_id: Scalars['String'];
  product_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootScanArgs = {
  distinct_on?: Maybe<Array<Scan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scan_Order_By>>;
  where?: Maybe<Scan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootScan_AggregateArgs = {
  distinct_on?: Maybe<Array<Scan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scan_Order_By>>;
  where?: Maybe<Scan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootScan_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSupplierArgs = {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSupplier_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSupplier_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSupplier_HistoryArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSupplier_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "supplier" */
export type Supplier = {
   __typename?: 'supplier';
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregated array relationship */
  orders_aggregate: Order_Aggregate;
  phone?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['Int']>;
  /** An array relationship */
  scans: Array<Scan>;
  /** An aggregated array relationship */
  scans_aggregate: Scan_Aggregate;
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  /** An array relationship */
  supplier_histories: Array<Supplier_History>;
  /** An aggregated array relationship */
  supplier_histories_aggregate: Supplier_History_Aggregate;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "supplier" */
export type SupplierDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "supplier" */
export type SupplierOrdersArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "supplier" */
export type SupplierOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "supplier" */
export type SupplierScansArgs = {
  distinct_on?: Maybe<Array<Scan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scan_Order_By>>;
  where?: Maybe<Scan_Bool_Exp>;
};


/** columns and relationships of "supplier" */
export type SupplierScans_AggregateArgs = {
  distinct_on?: Maybe<Array<Scan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scan_Order_By>>;
  where?: Maybe<Scan_Bool_Exp>;
};


/** columns and relationships of "supplier" */
export type SupplierSupplier_HistoriesArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** columns and relationships of "supplier" */
export type SupplierSupplier_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** columns and relationships of "supplier" */
export type SupplierTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "supplier" */
export type Supplier_Aggregate = {
   __typename?: 'supplier_aggregate';
  aggregate?: Maybe<Supplier_Aggregate_Fields>;
  nodes: Array<Supplier>;
};

/** aggregate fields of "supplier" */
export type Supplier_Aggregate_Fields = {
   __typename?: 'supplier_aggregate_fields';
  avg?: Maybe<Supplier_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Supplier_Max_Fields>;
  min?: Maybe<Supplier_Min_Fields>;
  stddev?: Maybe<Supplier_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_Sum_Fields>;
  var_pop?: Maybe<Supplier_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_Var_Samp_Fields>;
  variance?: Maybe<Supplier_Variance_Fields>;
};


/** aggregate fields of "supplier" */
export type Supplier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Supplier_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier" */
export type Supplier_Aggregate_Order_By = {
  avg?: Maybe<Supplier_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Supplier_Max_Order_By>;
  min?: Maybe<Supplier_Min_Order_By>;
  stddev?: Maybe<Supplier_Stddev_Order_By>;
  stddev_pop?: Maybe<Supplier_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Supplier_Stddev_Samp_Order_By>;
  sum?: Maybe<Supplier_Sum_Order_By>;
  var_pop?: Maybe<Supplier_Var_Pop_Order_By>;
  var_samp?: Maybe<Supplier_Var_Samp_Order_By>;
  variance?: Maybe<Supplier_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Supplier_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "supplier" */
export type Supplier_Arr_Rel_Insert_Input = {
  data: Array<Supplier_Insert_Input>;
  on_conflict?: Maybe<Supplier_On_Conflict>;
};

/** aggregate avg on columns */
export type Supplier_Avg_Fields = {
   __typename?: 'supplier_avg_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier" */
export type Supplier_Avg_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier". All fields are combined with a logical 'AND'. */
export type Supplier_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Supplier_Bool_Exp>>>;
  _not?: Maybe<Supplier_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Supplier_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Order_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  pipeline?: Maybe<Int_Comparison_Exp>;
  scans?: Maybe<Scan_Bool_Exp>;
  site?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Int_Comparison_Exp>;
  supplier_histories?: Maybe<Supplier_History_Bool_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "supplier" */
export enum Supplier_Constraint {
  /** unique or primary key constraint */
  SupplierIdKey = 'supplier_id_key',
  /** unique or primary key constraint */
  SuppliersPkey = 'suppliers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Supplier_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Supplier_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Supplier_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

/** columns and relationships of "supplier_history" */
export type Supplier_History = {
   __typename?: 'supplier_history';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  supplier: Supplier;
  supplier_id: Scalars['String'];
  text: Scalars['jsonb'];
  type: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "supplier_history" */
export type Supplier_HistoryTextArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "supplier_history" */
export type Supplier_History_Aggregate = {
   __typename?: 'supplier_history_aggregate';
  aggregate?: Maybe<Supplier_History_Aggregate_Fields>;
  nodes: Array<Supplier_History>;
};

/** aggregate fields of "supplier_history" */
export type Supplier_History_Aggregate_Fields = {
   __typename?: 'supplier_history_aggregate_fields';
  avg?: Maybe<Supplier_History_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Supplier_History_Max_Fields>;
  min?: Maybe<Supplier_History_Min_Fields>;
  stddev?: Maybe<Supplier_History_Stddev_Fields>;
  stddev_pop?: Maybe<Supplier_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Supplier_History_Stddev_Samp_Fields>;
  sum?: Maybe<Supplier_History_Sum_Fields>;
  var_pop?: Maybe<Supplier_History_Var_Pop_Fields>;
  var_samp?: Maybe<Supplier_History_Var_Samp_Fields>;
  variance?: Maybe<Supplier_History_Variance_Fields>;
};


/** aggregate fields of "supplier_history" */
export type Supplier_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Supplier_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "supplier_history" */
export type Supplier_History_Aggregate_Order_By = {
  avg?: Maybe<Supplier_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Supplier_History_Max_Order_By>;
  min?: Maybe<Supplier_History_Min_Order_By>;
  stddev?: Maybe<Supplier_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Supplier_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Supplier_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Supplier_History_Sum_Order_By>;
  var_pop?: Maybe<Supplier_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Supplier_History_Var_Samp_Order_By>;
  variance?: Maybe<Supplier_History_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Supplier_History_Append_Input = {
  text?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "supplier_history" */
export type Supplier_History_Arr_Rel_Insert_Input = {
  data: Array<Supplier_History_Insert_Input>;
};

/** aggregate avg on columns */
export type Supplier_History_Avg_Fields = {
   __typename?: 'supplier_history_avg_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "supplier_history" */
export type Supplier_History_Avg_Order_By = {
  type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "supplier_history". All fields are combined with a logical 'AND'. */
export type Supplier_History_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Supplier_History_Bool_Exp>>>;
  _not?: Maybe<Supplier_History_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Supplier_History_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  supplier?: Maybe<Supplier_Bool_Exp>;
  supplier_id?: Maybe<String_Comparison_Exp>;
  text?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Supplier_History_Delete_At_Path_Input = {
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Supplier_History_Delete_Elem_Input = {
  text?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Supplier_History_Delete_Key_Input = {
  text?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer columne in table "supplier_history" */
export type Supplier_History_Inc_Input = {
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "supplier_history" */
export type Supplier_History_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supplier_History_Max_Fields = {
   __typename?: 'supplier_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "supplier_history" */
export type Supplier_History_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_History_Min_Fields = {
   __typename?: 'supplier_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  supplier_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "supplier_history" */
export type Supplier_History_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "supplier_history" */
export type Supplier_History_Mutation_Response = {
   __typename?: 'supplier_history_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Supplier_History>;
};

/** input type for inserting object relation for remote table "supplier_history" */
export type Supplier_History_Obj_Rel_Insert_Input = {
  data: Supplier_History_Insert_Input;
};

/** ordering options when selecting data from "supplier_history" */
export type Supplier_History_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  supplier?: Maybe<Supplier_Order_By>;
  supplier_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Supplier_History_Prepend_Input = {
  text?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "supplier_history" */
export enum Supplier_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "supplier_history" */
export type Supplier_History_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  supplier_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supplier_History_Stddev_Fields = {
   __typename?: 'supplier_history_stddev_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier_history" */
export type Supplier_History_Stddev_Order_By = {
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_History_Stddev_Pop_Fields = {
   __typename?: 'supplier_history_stddev_pop_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier_history" */
export type Supplier_History_Stddev_Pop_Order_By = {
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_History_Stddev_Samp_Fields = {
   __typename?: 'supplier_history_stddev_samp_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier_history" */
export type Supplier_History_Stddev_Samp_Order_By = {
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Supplier_History_Sum_Fields = {
   __typename?: 'supplier_history_sum_fields';
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "supplier_history" */
export type Supplier_History_Sum_Order_By = {
  type?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Supplier_History_Var_Pop_Fields = {
   __typename?: 'supplier_history_var_pop_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier_history" */
export type Supplier_History_Var_Pop_Order_By = {
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_History_Var_Samp_Fields = {
   __typename?: 'supplier_history_var_samp_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier_history" */
export type Supplier_History_Var_Samp_Order_By = {
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_History_Variance_Fields = {
   __typename?: 'supplier_history_variance_fields';
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier_history" */
export type Supplier_History_Variance_Order_By = {
  type?: Maybe<Order_By>;
};

/** input type for incrementing integer columne in table "supplier" */
export type Supplier_Inc_Input = {
  pipeline?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "supplier" */
export type Supplier_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['Int']>;
  scans?: Maybe<Scan_Arr_Rel_Insert_Input>;
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  supplier_histories?: Maybe<Supplier_History_Arr_Rel_Insert_Input>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Supplier_Max_Fields = {
   __typename?: 'supplier_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "supplier" */
export type Supplier_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  pipeline?: Maybe<Order_By>;
  site?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Supplier_Min_Fields = {
   __typename?: 'supplier_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "supplier" */
export type Supplier_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  pipeline?: Maybe<Order_By>;
  site?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "supplier" */
export type Supplier_Mutation_Response = {
   __typename?: 'supplier_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Supplier>;
};

/** input type for inserting object relation for remote table "supplier" */
export type Supplier_Obj_Rel_Insert_Input = {
  data: Supplier_Insert_Input;
  on_conflict?: Maybe<Supplier_On_Conflict>;
};

/** on conflict condition type for table "supplier" */
export type Supplier_On_Conflict = {
  constraint: Supplier_Constraint;
  update_columns: Array<Supplier_Update_Column>;
  where?: Maybe<Supplier_Bool_Exp>;
};

/** ordering options when selecting data from "supplier" */
export type Supplier_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  pipeline?: Maybe<Order_By>;
  scans_aggregate?: Maybe<Scan_Aggregate_Order_By>;
  site?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supplier_histories_aggregate?: Maybe<Supplier_History_Aggregate_Order_By>;
  tags?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Supplier_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "supplier" */
export enum Supplier_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Pipeline = 'pipeline',
  /** column name */
  Site = 'site',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "supplier" */
export type Supplier_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pipeline?: Maybe<Scalars['Int']>;
  site?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Supplier_Stddev_Fields = {
   __typename?: 'supplier_stddev_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "supplier" */
export type Supplier_Stddev_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Supplier_Stddev_Pop_Fields = {
   __typename?: 'supplier_stddev_pop_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "supplier" */
export type Supplier_Stddev_Pop_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Supplier_Stddev_Samp_Fields = {
   __typename?: 'supplier_stddev_samp_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "supplier" */
export type Supplier_Stddev_Samp_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Supplier_Sum_Fields = {
   __typename?: 'supplier_sum_fields';
  pipeline?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "supplier" */
export type Supplier_Sum_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** update columns of table "supplier" */
export enum Supplier_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Pipeline = 'pipeline',
  /** column name */
  Site = 'site',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Supplier_Var_Pop_Fields = {
   __typename?: 'supplier_var_pop_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "supplier" */
export type Supplier_Var_Pop_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Supplier_Var_Samp_Fields = {
   __typename?: 'supplier_var_samp_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "supplier" */
export type Supplier_Var_Samp_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Supplier_Variance_Fields = {
   __typename?: 'supplier_variance_fields';
  pipeline?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "supplier" */
export type Supplier_Variance_Order_By = {
  pipeline?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
   __typename?: 'user';
  auth0_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  company?: Maybe<Company>;
  company_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  last_seen?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  messages: Array<Message>;
  /** An aggregated array relationship */
  messages_aggregate: Message_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  supplier_histories: Array<Supplier_History>;
  /** An aggregated array relationship */
  supplier_histories_aggregate: Supplier_History_Aggregate;
  /** An array relationship */
  suppliers: Array<Supplier>;
  /** An aggregated array relationship */
  suppliers_aggregate: Supplier_Aggregate;
};


/** columns and relationships of "user" */
export type UserMessagesArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSupplier_HistoriesArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSupplier_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_History_Order_By>>;
  where?: Maybe<Supplier_History_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSuppliersArgs = {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSuppliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Supplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Supplier_Order_By>>;
  where?: Maybe<Supplier_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
   __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
   __typename?: 'user_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  auth0_id?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  messages?: Maybe<Message_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  supplier_histories?: Maybe<Supplier_History_Bool_Exp>;
  suppliers?: Maybe<Supplier_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserIdKey = 'user_id_key',
  /** unique or primary key constraint */
  UsersAuth0IdKey = 'users_auth0_id_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  auth0_id?: Maybe<Scalars['String']>;
  company?: Maybe<Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  messages?: Maybe<Message_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  supplier_histories?: Maybe<Supplier_History_Arr_Rel_Insert_Input>;
  suppliers?: Maybe<Supplier_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
   __typename?: 'user_max_fields';
  auth0_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  auth0_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
   __typename?: 'user_min_fields';
  auth0_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  auth0_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
   __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  auth0_id?: Maybe<Order_By>;
  company?: Maybe<Company_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  messages_aggregate?: Maybe<Message_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  supplier_histories_aggregate?: Maybe<Supplier_History_Aggregate_Order_By>;
  suppliers_aggregate?: Maybe<Supplier_Aggregate_Order_By>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  auth0_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name'
}

export type OrderFieldsFragment = (
  { __typename?: 'order' }
  & Pick<Order, 'created_at' | 'data' | 'id' | 'name' | 'status' | 'type' | 'supplier_id'>
  & { supplier?: Maybe<(
    { __typename?: 'supplier' }
    & Pick<Supplier, 'name'>
  )> }
);

export type GetOrderByIdQueryVariables = {
  id: Scalars['String'];
};


export type GetOrderByIdQuery = (
  { __typename?: 'query_root' }
  & { order_by_pk?: Maybe<(
    { __typename?: 'order' }
    & { order_products: Array<(
      { __typename?: 'order_product' }
      & Pick<Order_Product, 'order_id' | 'product_id' | 'buyCost' | 'prepCost' | 'data' | 'status' | 'qty' | 'created_at'>
      & { product: (
        { __typename?: 'product' }
        & ProductFieldsFragment
      ) }
    )> }
    & OrderFieldsFragment
  )> }
);

export type GetOrdersBySupplierIdQueryVariables = {
  id: Scalars['String'];
};


export type GetOrdersBySupplierIdQuery = (
  { __typename?: 'query_root' }
  & { order: Array<(
    { __typename?: 'order' }
    & OrderFieldsFragment
  )> }
);

export type InsertOrderMutationVariables = {
  order: Array<Order_Insert_Input>;
};


export type InsertOrderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type InsertOrderProductMutationVariables = {
  data: Array<Order_Product_Insert_Input>;
};


export type InsertOrderProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_order_product?: Maybe<(
    { __typename?: 'order_product_mutation_response' }
    & Pick<Order_Product_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateOrderMutationVariables = {
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Order_Set_Input>;
};


export type UpdateOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type UpdateOrderProductMutationVariables = {
  order_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  data?: Maybe<Order_Product_Set_Input>;
};


export type UpdateOrderProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_order_product?: Maybe<(
    { __typename?: 'order_product_mutation_response' }
    & Pick<Order_Product_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteOrderMutationVariables = {
  id: Scalars['String'];
};


export type DeleteOrderMutation = (
  { __typename?: 'mutation_root' }
  & { delete_order?: Maybe<(
    { __typename?: 'order_mutation_response' }
    & Pick<Order_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteOrderProductMutationVariables = {
  product_id: Scalars['String'];
  order_id: Scalars['String'];
};


export type DeleteOrderProductMutation = (
  { __typename?: 'mutation_root' }
  & { delete_order_product?: Maybe<(
    { __typename?: 'order_product_mutation_response' }
    & Pick<Order_Product_Mutation_Response, 'affected_rows'>
  )> }
);

export type ProductFieldsFragment = (
  { __typename?: 'product' }
  & Pick<Product, 'amazon_extra_info' | 'asin' | 'created_at' | 'description' | 'ebay_extra_info' | 'id' | 'last_update_at' | 'name' | 'note' | 'price' | 'pricemax' | 'pricemin' | 'status' | 'tags' | 'type' | 'upc'>
);

export type GetAllProductsQueryVariables = {};


export type GetAllProductsQuery = (
  { __typename?: 'query_root' }
  & { product: Array<(
    { __typename?: 'product' }
    & ProductFieldsFragment
  )> }
);

export type GetProductByIdQueryVariables = {
  id: Scalars['String'];
};


export type GetProductByIdQuery = (
  { __typename?: 'query_root' }
  & { product_by_pk?: Maybe<(
    { __typename?: 'product' }
    & ProductFieldsFragment
  )> }
);

export type InsertProductMutationVariables = {
  product: Array<Product_Insert_Input>;
};


export type InsertProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & Pick<Product_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'asin'>
    )> }
  )> }
);

export type UpdateProductMutationVariables = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<Product_Set_Input>;
};


export type UpdateProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & Pick<Product_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'product' }
      & Pick<Product, 'id'>
    )> }
  )> }
);

export type DeleteProductMutationVariables = {
  id: Scalars['String'];
};


export type DeleteProductMutation = (
  { __typename?: 'mutation_root' }
  & { delete_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & Pick<Product_Mutation_Response, 'affected_rows'>
  )> }
);

export type ScanFieldsFragment = (
  { __typename?: 'scan' }
  & Pick<Scan, 'id' | 'process_id' | 'supplier_id' | 'data' | 'status' | 'type' | 'created_at'>
);

export type GetScanByIdQueryVariables = {
  id: Scalars['String'];
};


export type GetScanByIdQuery = (
  { __typename?: 'query_root' }
  & { scan_by_pk?: Maybe<(
    { __typename?: 'scan' }
    & ScanFieldsFragment
  )> }
);

export type GetScanByProcessIdQueryVariables = {
  id: Scalars['Int'];
};


export type GetScanByProcessIdQuery = (
  { __typename?: 'query_root' }
  & { scan: Array<(
    { __typename?: 'scan' }
    & ScanFieldsFragment
  )> }
);

export type GetScanBySupplierIdQueryVariables = {
  id: Scalars['String'];
};


export type GetScanBySupplierIdQuery = (
  { __typename?: 'query_root' }
  & { scan: Array<(
    { __typename?: 'scan' }
    & ScanFieldsFragment
  )> }
);

export type InsertScanMutationVariables = {
  data: Array<Scan_Insert_Input>;
};


export type InsertScanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_scan?: Maybe<(
    { __typename?: 'scan_mutation_response' }
    & Pick<Scan_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'scan' }
      & Pick<Scan, 'id'>
    )> }
  )> }
);

export type UpdateScanMutationVariables = {
  id?: Maybe<Scalars['String']>;
  data?: Maybe<Scan_Set_Input>;
};


export type UpdateScanMutation = (
  { __typename?: 'mutation_root' }
  & { update_scan?: Maybe<(
    { __typename?: 'scan_mutation_response' }
    & Pick<Scan_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'scan' }
      & Pick<Scan, 'id'>
    )> }
  )> }
);

export type DeleteScanMutationVariables = {
  id: Scalars['String'];
};


export type DeleteScanMutation = (
  { __typename?: 'mutation_root' }
  & { delete_scan?: Maybe<(
    { __typename?: 'scan_mutation_response' }
    & Pick<Scan_Mutation_Response, 'affected_rows'>
  )> }
);

export type SupplierFieldsFragment = (
  { __typename?: 'supplier' }
  & Pick<Supplier, 'id' | 'name' | 'phone' | 'site' | 'status' | 'type' | 'description' | 'created_at' | 'email' | 'tags' | 'user_id' | 'pipeline' | 'data'>
);

export type GetAllSuppliersQueryVariables = {};


export type GetAllSuppliersQuery = (
  { __typename?: 'query_root' }
  & { supplier: Array<(
    { __typename?: 'supplier' }
    & SupplierFieldsFragment
  )> }
);

export type GetSupplierByIdQueryVariables = {
  id: Scalars['String'];
};


export type GetSupplierByIdQuery = (
  { __typename?: 'query_root' }
  & { supplier_by_pk?: Maybe<(
    { __typename?: 'supplier' }
    & { orders: Array<(
      { __typename?: 'order' }
      & Pick<Order, 'id' | 'data' | 'name' | 'status' | 'created_at'>
    )> }
    & SupplierFieldsFragment
  )>, supplier_history: Array<(
    { __typename?: 'supplier_history' }
    & Pick<Supplier_History, 'id' | 'text' | 'type' | 'created_at' | 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'name'>
    )> }
  )> }
);

export type Insert_SuppliersMutationVariables = {
  supplier: Array<Supplier_Insert_Input>;
};


export type Insert_SuppliersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_supplier?: Maybe<(
    { __typename?: 'supplier_mutation_response' }
    & Pick<Supplier_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateSupplierMutationVariables = {
  id?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier_Set_Input>;
};


export type UpdateSupplierMutation = (
  { __typename?: 'mutation_root' }
  & { update_supplier?: Maybe<(
    { __typename?: 'supplier_mutation_response' }
    & Pick<Supplier_Mutation_Response, 'affected_rows'>
  )> }
);

export type Delete_SuppliersMutationVariables = {
  id: Scalars['String'];
};


export type Delete_SuppliersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_supplier?: Maybe<(
    { __typename?: 'supplier_mutation_response' }
    & Pick<Supplier_Mutation_Response, 'affected_rows'>
  )> }
);

export type InsertSupplierHistoryMutationVariables = {
  history: Array<Supplier_History_Insert_Input>;
};


export type InsertSupplierHistoryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_supplier_history?: Maybe<(
    { __typename?: 'supplier_history_mutation_response' }
    & Pick<Supplier_History_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateSupplierHistoryMutationVariables = {
  id?: Maybe<Scalars['String']>;
  supplierHistory?: Maybe<Supplier_History_Set_Input>;
};


export type UpdateSupplierHistoryMutation = (
  { __typename?: 'mutation_root' }
  & { update_supplier_history?: Maybe<(
    { __typename?: 'supplier_history_mutation_response' }
    & Pick<Supplier_History_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteSupplierHistoryMutationVariables = {
  id: Scalars['String'];
};


export type DeleteSupplierHistoryMutation = (
  { __typename?: 'mutation_root' }
  & { delete_supplier_history?: Maybe<(
    { __typename?: 'supplier_history_mutation_response' }
    & Pick<Supplier_History_Mutation_Response, 'affected_rows'>
  )> }
);

export type CompanyFieldsFragment = (
  { __typename?: 'company' }
  & Pick<Company, 'created_at' | 'description' | 'email' | 'name' | 'id' | 'settings' | 'status' | 'type'>
);

export type UserFieldsFragment = (
  { __typename?: 'user' }
  & Pick<User, 'auth0_id' | 'company_id' | 'created_at' | 'id' | 'last_seen' | 'name'>
  & { company?: Maybe<(
    { __typename?: 'company' }
    & CompanyFieldsFragment
  )> }
);

export type GetUserByAuthIdQueryVariables = {
  authId: Scalars['String'];
};


export type GetUserByAuthIdQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & UserFieldsFragment
  )> }
);

export type UpdateUserMutationVariables = {
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User_Set_Input>;
};


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'user_mutation_response' }
    & Pick<User_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCompanyMutationVariables = {
  id?: Maybe<Scalars['String']>;
  company?: Maybe<Company_Set_Input>;
};


export type UpdateCompanyMutation = (
  { __typename?: 'mutation_root' }
  & { update_company?: Maybe<(
    { __typename?: 'company_mutation_response' }
    & Pick<Company_Mutation_Response, 'affected_rows'>
  )> }
);

export type MessageFieldsFragment = (
  { __typename?: 'message' }
  & Pick<Message, 'id' | 'text' | 'created_at'>
  & { user: (
    { __typename?: 'user' }
    & Pick<User, 'name'>
  ) }
);

export type RoomMessagesSubscriptionVariables = {
  room?: Maybe<Scalars['String']>;
};


export type RoomMessagesSubscription = (
  { __typename?: 'subscription_root' }
  & { message: Array<(
    { __typename?: 'message' }
    & MessageFieldsFragment
  )> }
);

export type RoomMessageCountSubscriptionVariables = {
  rooms?: Maybe<Array<Scalars['String']>>;
};


export type RoomMessageCountSubscription = (
  { __typename?: 'subscription_root' }
  & { message_count: Array<(
    { __typename?: 'message_count' }
    & Pick<Message_Count, 'count' | 'room'>
  )> }
);

export type InsertMessageMutationVariables = {
  message: Array<Message_Insert_Input>;
};


export type InsertMessageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_message?: Maybe<(
    { __typename?: 'message_mutation_response' }
    & Pick<Message_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'message' }
      & Pick<Message, 'id'>
    )> }
  )> }
);

export const OrderFieldsFragmentDoc = gql`
    fragment orderFields on order {
  created_at
  data
  id
  name
  status
  type
  supplier_id
  supplier {
    name
  }
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment productFields on product {
  amazon_extra_info
  asin
  created_at
  description
  ebay_extra_info
  id
  last_update_at
  name
  note
  price
  pricemax
  pricemin
  status
  tags
  type
  upc
}
    `;
export const ScanFieldsFragmentDoc = gql`
    fragment scanFields on scan {
  id
  process_id
  supplier_id
  data
  status
  type
  created_at
}
    `;
export const SupplierFieldsFragmentDoc = gql`
    fragment supplierFields on supplier {
  id
  name
  phone
  site
  status
  type
  description
  created_at
  email
  tags
  user_id
  pipeline
  data
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment companyFields on company {
  created_at
  description
  email
  name
  id
  settings
  status
  type
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on user {
  auth0_id
  company_id
  created_at
  id
  last_seen
  name
  company {
    ...companyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;
export const MessageFieldsFragmentDoc = gql`
    fragment messageFields on message {
  id
  text
  user {
    name
  }
  created_at
}
    `;
export const GetOrderByIdDocument = gql`
    query getOrderById($id: String!) {
  order_by_pk(id: $id) {
    ...orderFields
    order_products {
      order_id
      product_id
      buyCost
      prepCost
      data
      status
      qty
      product {
        ...productFields
      }
      created_at
    }
  }
}
    ${OrderFieldsFragmentDoc}
${ProductFieldsFragmentDoc}`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, baseOptions);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, baseOptions);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = ApolloReactCommon.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export const GetOrdersBySupplierIdDocument = gql`
    query getOrdersBySupplierId($id: String!) {
  order(where: {supplier_id: {_eq: $id}}) {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useGetOrdersBySupplierIdQuery__
 *
 * To run a query within a React component, call `useGetOrdersBySupplierIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersBySupplierIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersBySupplierIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrdersBySupplierIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrdersBySupplierIdQuery, GetOrdersBySupplierIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrdersBySupplierIdQuery, GetOrdersBySupplierIdQueryVariables>(GetOrdersBySupplierIdDocument, baseOptions);
      }
export function useGetOrdersBySupplierIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrdersBySupplierIdQuery, GetOrdersBySupplierIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrdersBySupplierIdQuery, GetOrdersBySupplierIdQueryVariables>(GetOrdersBySupplierIdDocument, baseOptions);
        }
export type GetOrdersBySupplierIdQueryHookResult = ReturnType<typeof useGetOrdersBySupplierIdQuery>;
export type GetOrdersBySupplierIdLazyQueryHookResult = ReturnType<typeof useGetOrdersBySupplierIdLazyQuery>;
export type GetOrdersBySupplierIdQueryResult = ApolloReactCommon.QueryResult<GetOrdersBySupplierIdQuery, GetOrdersBySupplierIdQueryVariables>;
export const InsertOrderDocument = gql`
    mutation insertOrder($order: [order_insert_input!]!) {
  insert_order(objects: $order) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type InsertOrderMutationFn = ApolloReactCommon.MutationFunction<InsertOrderMutation, InsertOrderMutationVariables>;

/**
 * __useInsertOrderMutation__
 *
 * To run a mutation, you first call `useInsertOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderMutation, { data, loading, error }] = useInsertOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInsertOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOrderMutation, InsertOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertOrderMutation, InsertOrderMutationVariables>(InsertOrderDocument, baseOptions);
      }
export type InsertOrderMutationHookResult = ReturnType<typeof useInsertOrderMutation>;
export type InsertOrderMutationResult = ApolloReactCommon.MutationResult<InsertOrderMutation>;
export type InsertOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOrderMutation, InsertOrderMutationVariables>;
export const InsertOrderProductDocument = gql`
    mutation insertOrderProduct($data: [order_product_insert_input!]!) {
  insert_order_product(objects: $data) {
    affected_rows
  }
}
    `;
export type InsertOrderProductMutationFn = ApolloReactCommon.MutationFunction<InsertOrderProductMutation, InsertOrderProductMutationVariables>;

/**
 * __useInsertOrderProductMutation__
 *
 * To run a mutation, you first call `useInsertOrderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderProductMutation, { data, loading, error }] = useInsertOrderProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertOrderProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOrderProductMutation, InsertOrderProductMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertOrderProductMutation, InsertOrderProductMutationVariables>(InsertOrderProductDocument, baseOptions);
      }
export type InsertOrderProductMutationHookResult = ReturnType<typeof useInsertOrderProductMutation>;
export type InsertOrderProductMutationResult = ApolloReactCommon.MutationResult<InsertOrderProductMutation>;
export type InsertOrderProductMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOrderProductMutation, InsertOrderProductMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($id: String, $order: order_set_input) {
  update_order(where: {id: {_eq: $id}}, _set: $order) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, baseOptions);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = ApolloReactCommon.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateOrderProductDocument = gql`
    mutation updateOrderProduct($order_id: String, $product_id: String, $data: order_product_set_input) {
  update_order_product(where: {product_id: {_eq: $product_id}, order_id: {_eq: $order_id}}, _set: $data) {
    affected_rows
  }
}
    `;
export type UpdateOrderProductMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderProductMutation, UpdateOrderProductMutationVariables>;

/**
 * __useUpdateOrderProductMutation__
 *
 * To run a mutation, you first call `useUpdateOrderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderProductMutation, { data, loading, error }] = useUpdateOrderProductMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *      product_id: // value for 'product_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderProductMutation, UpdateOrderProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderProductMutation, UpdateOrderProductMutationVariables>(UpdateOrderProductDocument, baseOptions);
      }
export type UpdateOrderProductMutationHookResult = ReturnType<typeof useUpdateOrderProductMutation>;
export type UpdateOrderProductMutationResult = ApolloReactCommon.MutationResult<UpdateOrderProductMutation>;
export type UpdateOrderProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderProductMutation, UpdateOrderProductMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($id: String!) {
  delete_order(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteOrderMutationFn = ApolloReactCommon.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, baseOptions);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = ApolloReactCommon.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const DeleteOrderProductDocument = gql`
    mutation deleteOrderProduct($product_id: String!, $order_id: String!) {
  delete_order_product(where: {product_id: {_eq: $product_id}, order_id: {_eq: $order_id}}) {
    affected_rows
  }
}
    `;
export type DeleteOrderProductMutationFn = ApolloReactCommon.MutationFunction<DeleteOrderProductMutation, DeleteOrderProductMutationVariables>;

/**
 * __useDeleteOrderProductMutation__
 *
 * To run a mutation, you first call `useDeleteOrderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderProductMutation, { data, loading, error }] = useDeleteOrderProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useDeleteOrderProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrderProductMutation, DeleteOrderProductMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrderProductMutation, DeleteOrderProductMutationVariables>(DeleteOrderProductDocument, baseOptions);
      }
export type DeleteOrderProductMutationHookResult = ReturnType<typeof useDeleteOrderProductMutation>;
export type DeleteOrderProductMutationResult = ApolloReactCommon.MutationResult<DeleteOrderProductMutation>;
export type DeleteOrderProductMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrderProductMutation, DeleteOrderProductMutationVariables>;
export const GetAllProductsDocument = gql`
    query getAllProducts {
  product(order_by: {created_at: desc}) {
    ...productFields
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, baseOptions);
      }
export function useGetAllProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, baseOptions);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = ApolloReactCommon.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetProductByIdDocument = gql`
    query getProductById($id: String!) {
  product_by_pk(id: $id) {
    ...productFields
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useGetProductByIdQuery__
 *
 * To run a query within a React component, call `useGetProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductByIdQuery, GetProductByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
      }
export function useGetProductByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductByIdQuery, GetProductByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
        }
export type GetProductByIdQueryHookResult = ReturnType<typeof useGetProductByIdQuery>;
export type GetProductByIdLazyQueryHookResult = ReturnType<typeof useGetProductByIdLazyQuery>;
export type GetProductByIdQueryResult = ApolloReactCommon.QueryResult<GetProductByIdQuery, GetProductByIdQueryVariables>;
export const InsertProductDocument = gql`
    mutation insertProduct($product: [product_insert_input!]!) {
  insert_product(objects: $product) {
    affected_rows
    returning {
      id
      asin
    }
  }
}
    `;
export type InsertProductMutationFn = ApolloReactCommon.MutationFunction<InsertProductMutation, InsertProductMutationVariables>;

/**
 * __useInsertProductMutation__
 *
 * To run a mutation, you first call `useInsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductMutation, { data, loading, error }] = useInsertProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useInsertProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertProductMutation, InsertProductMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertProductMutation, InsertProductMutationVariables>(InsertProductDocument, baseOptions);
      }
export type InsertProductMutationHookResult = ReturnType<typeof useInsertProductMutation>;
export type InsertProductMutationResult = ApolloReactCommon.MutationResult<InsertProductMutation>;
export type InsertProductMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertProductMutation, InsertProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: String, $product: product_set_input) {
  update_product(where: {id: {_eq: $id}}, _set: $product) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: String!) {
  delete_product(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteProductMutationFn = ApolloReactCommon.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, baseOptions);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = ApolloReactCommon.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const GetScanByIdDocument = gql`
    query getScanById($id: String!) {
  scan_by_pk(id: $id) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetScanByIdQuery__
 *
 * To run a query within a React component, call `useGetScanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScanByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScanByIdQuery, GetScanByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScanByIdQuery, GetScanByIdQueryVariables>(GetScanByIdDocument, baseOptions);
      }
export function useGetScanByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScanByIdQuery, GetScanByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScanByIdQuery, GetScanByIdQueryVariables>(GetScanByIdDocument, baseOptions);
        }
export type GetScanByIdQueryHookResult = ReturnType<typeof useGetScanByIdQuery>;
export type GetScanByIdLazyQueryHookResult = ReturnType<typeof useGetScanByIdLazyQuery>;
export type GetScanByIdQueryResult = ApolloReactCommon.QueryResult<GetScanByIdQuery, GetScanByIdQueryVariables>;
export const GetScanByProcessIdDocument = gql`
    query getScanByProcessId($id: Int!) {
  scan(where: {process_id: {_eq: $id}}) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetScanByProcessIdQuery__
 *
 * To run a query within a React component, call `useGetScanByProcessIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanByProcessIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanByProcessIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScanByProcessIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScanByProcessIdQuery, GetScanByProcessIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScanByProcessIdQuery, GetScanByProcessIdQueryVariables>(GetScanByProcessIdDocument, baseOptions);
      }
export function useGetScanByProcessIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScanByProcessIdQuery, GetScanByProcessIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScanByProcessIdQuery, GetScanByProcessIdQueryVariables>(GetScanByProcessIdDocument, baseOptions);
        }
export type GetScanByProcessIdQueryHookResult = ReturnType<typeof useGetScanByProcessIdQuery>;
export type GetScanByProcessIdLazyQueryHookResult = ReturnType<typeof useGetScanByProcessIdLazyQuery>;
export type GetScanByProcessIdQueryResult = ApolloReactCommon.QueryResult<GetScanByProcessIdQuery, GetScanByProcessIdQueryVariables>;
export const GetScanBySupplierIdDocument = gql`
    query getScanBySupplierId($id: String!) {
  scan(where: {supplier_id: {_eq: $id}}, order_by: {created_at: desc}) {
    ...scanFields
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetScanBySupplierIdQuery__
 *
 * To run a query within a React component, call `useGetScanBySupplierIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanBySupplierIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanBySupplierIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScanBySupplierIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScanBySupplierIdQuery, GetScanBySupplierIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScanBySupplierIdQuery, GetScanBySupplierIdQueryVariables>(GetScanBySupplierIdDocument, baseOptions);
      }
export function useGetScanBySupplierIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScanBySupplierIdQuery, GetScanBySupplierIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScanBySupplierIdQuery, GetScanBySupplierIdQueryVariables>(GetScanBySupplierIdDocument, baseOptions);
        }
export type GetScanBySupplierIdQueryHookResult = ReturnType<typeof useGetScanBySupplierIdQuery>;
export type GetScanBySupplierIdLazyQueryHookResult = ReturnType<typeof useGetScanBySupplierIdLazyQuery>;
export type GetScanBySupplierIdQueryResult = ApolloReactCommon.QueryResult<GetScanBySupplierIdQuery, GetScanBySupplierIdQueryVariables>;
export const InsertScanDocument = gql`
    mutation insertScan($data: [scan_insert_input!]!) {
  insert_scan(objects: $data) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type InsertScanMutationFn = ApolloReactCommon.MutationFunction<InsertScanMutation, InsertScanMutationVariables>;

/**
 * __useInsertScanMutation__
 *
 * To run a mutation, you first call `useInsertScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertScanMutation, { data, loading, error }] = useInsertScanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertScanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertScanMutation, InsertScanMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertScanMutation, InsertScanMutationVariables>(InsertScanDocument, baseOptions);
      }
export type InsertScanMutationHookResult = ReturnType<typeof useInsertScanMutation>;
export type InsertScanMutationResult = ApolloReactCommon.MutationResult<InsertScanMutation>;
export type InsertScanMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertScanMutation, InsertScanMutationVariables>;
export const UpdateScanDocument = gql`
    mutation updateScan($id: String, $data: scan_set_input) {
  update_scan(where: {id: {_eq: $id}}, _set: $data) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateScanMutationFn = ApolloReactCommon.MutationFunction<UpdateScanMutation, UpdateScanMutationVariables>;

/**
 * __useUpdateScanMutation__
 *
 * To run a mutation, you first call `useUpdateScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScanMutation, { data, loading, error }] = useUpdateScanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateScanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScanMutation, UpdateScanMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScanMutation, UpdateScanMutationVariables>(UpdateScanDocument, baseOptions);
      }
export type UpdateScanMutationHookResult = ReturnType<typeof useUpdateScanMutation>;
export type UpdateScanMutationResult = ApolloReactCommon.MutationResult<UpdateScanMutation>;
export type UpdateScanMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScanMutation, UpdateScanMutationVariables>;
export const DeleteScanDocument = gql`
    mutation deleteScan($id: String!) {
  delete_scan(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteScanMutationFn = ApolloReactCommon.MutationFunction<DeleteScanMutation, DeleteScanMutationVariables>;

/**
 * __useDeleteScanMutation__
 *
 * To run a mutation, you first call `useDeleteScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScanMutation, { data, loading, error }] = useDeleteScanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteScanMutation, DeleteScanMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteScanMutation, DeleteScanMutationVariables>(DeleteScanDocument, baseOptions);
      }
export type DeleteScanMutationHookResult = ReturnType<typeof useDeleteScanMutation>;
export type DeleteScanMutationResult = ApolloReactCommon.MutationResult<DeleteScanMutation>;
export type DeleteScanMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScanMutation, DeleteScanMutationVariables>;
export const GetAllSuppliersDocument = gql`
    query getAllSuppliers {
  supplier(order_by: {created_at: desc}) {
    ...supplierFields
  }
}
    ${SupplierFieldsFragmentDoc}`;

/**
 * __useGetAllSuppliersQuery__
 *
 * To run a query within a React component, call `useGetAllSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSuppliersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>(GetAllSuppliersDocument, baseOptions);
      }
export function useGetAllSuppliersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>(GetAllSuppliersDocument, baseOptions);
        }
export type GetAllSuppliersQueryHookResult = ReturnType<typeof useGetAllSuppliersQuery>;
export type GetAllSuppliersLazyQueryHookResult = ReturnType<typeof useGetAllSuppliersLazyQuery>;
export type GetAllSuppliersQueryResult = ApolloReactCommon.QueryResult<GetAllSuppliersQuery, GetAllSuppliersQueryVariables>;
export const GetSupplierByIdDocument = gql`
    query getSupplierById($id: String!) {
  supplier_by_pk(id: $id) {
    ...supplierFields
    orders {
      id
      data
      name
      status
      created_at
    }
  }
  supplier_history(where: {supplier_id: {_eq: $id}}, order_by: {created_at: asc}) {
    id
    text
    type
    created_at
    user_id
    user {
      name
    }
  }
}
    ${SupplierFieldsFragmentDoc}`;

/**
 * __useGetSupplierByIdQuery__
 *
 * To run a query within a React component, call `useGetSupplierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupplierByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByIdQuery, GetSupplierByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByIdQuery, GetSupplierByIdQueryVariables>(GetSupplierByIdDocument, baseOptions);
      }
export function useGetSupplierByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByIdQuery, GetSupplierByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByIdQuery, GetSupplierByIdQueryVariables>(GetSupplierByIdDocument, baseOptions);
        }
export type GetSupplierByIdQueryHookResult = ReturnType<typeof useGetSupplierByIdQuery>;
export type GetSupplierByIdLazyQueryHookResult = ReturnType<typeof useGetSupplierByIdLazyQuery>;
export type GetSupplierByIdQueryResult = ApolloReactCommon.QueryResult<GetSupplierByIdQuery, GetSupplierByIdQueryVariables>;
export const Insert_SuppliersDocument = gql`
    mutation insert_suppliers($supplier: [supplier_insert_input!]!) {
  insert_supplier(objects: $supplier) {
    affected_rows
  }
}
    `;
export type Insert_SuppliersMutationFn = ApolloReactCommon.MutationFunction<Insert_SuppliersMutation, Insert_SuppliersMutationVariables>;

/**
 * __useInsert_SuppliersMutation__
 *
 * To run a mutation, you first call `useInsert_SuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_SuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSuppliersMutation, { data, loading, error }] = useInsert_SuppliersMutation({
 *   variables: {
 *      supplier: // value for 'supplier'
 *   },
 * });
 */
export function useInsert_SuppliersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Insert_SuppliersMutation, Insert_SuppliersMutationVariables>) {
        return ApolloReactHooks.useMutation<Insert_SuppliersMutation, Insert_SuppliersMutationVariables>(Insert_SuppliersDocument, baseOptions);
      }
export type Insert_SuppliersMutationHookResult = ReturnType<typeof useInsert_SuppliersMutation>;
export type Insert_SuppliersMutationResult = ApolloReactCommon.MutationResult<Insert_SuppliersMutation>;
export type Insert_SuppliersMutationOptions = ApolloReactCommon.BaseMutationOptions<Insert_SuppliersMutation, Insert_SuppliersMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation updateSupplier($id: String, $supplier: supplier_set_input) {
  update_supplier(where: {id: {_eq: $id}}, _set: $supplier) {
    affected_rows
  }
}
    `;
export type UpdateSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      supplier: // value for 'supplier'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, baseOptions);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const Delete_SuppliersDocument = gql`
    mutation delete_suppliers($id: String!) {
  delete_supplier(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type Delete_SuppliersMutationFn = ApolloReactCommon.MutationFunction<Delete_SuppliersMutation, Delete_SuppliersMutationVariables>;

/**
 * __useDelete_SuppliersMutation__
 *
 * To run a mutation, you first call `useDelete_SuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_SuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSuppliersMutation, { data, loading, error }] = useDelete_SuppliersMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_SuppliersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_SuppliersMutation, Delete_SuppliersMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_SuppliersMutation, Delete_SuppliersMutationVariables>(Delete_SuppliersDocument, baseOptions);
      }
export type Delete_SuppliersMutationHookResult = ReturnType<typeof useDelete_SuppliersMutation>;
export type Delete_SuppliersMutationResult = ApolloReactCommon.MutationResult<Delete_SuppliersMutation>;
export type Delete_SuppliersMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_SuppliersMutation, Delete_SuppliersMutationVariables>;
export const InsertSupplierHistoryDocument = gql`
    mutation insertSupplierHistory($history: [supplier_history_insert_input!]!) {
  insert_supplier_history(objects: $history) {
    affected_rows
  }
}
    `;
export type InsertSupplierHistoryMutationFn = ApolloReactCommon.MutationFunction<InsertSupplierHistoryMutation, InsertSupplierHistoryMutationVariables>;

/**
 * __useInsertSupplierHistoryMutation__
 *
 * To run a mutation, you first call `useInsertSupplierHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSupplierHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSupplierHistoryMutation, { data, loading, error }] = useInsertSupplierHistoryMutation({
 *   variables: {
 *      history: // value for 'history'
 *   },
 * });
 */
export function useInsertSupplierHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertSupplierHistoryMutation, InsertSupplierHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertSupplierHistoryMutation, InsertSupplierHistoryMutationVariables>(InsertSupplierHistoryDocument, baseOptions);
      }
export type InsertSupplierHistoryMutationHookResult = ReturnType<typeof useInsertSupplierHistoryMutation>;
export type InsertSupplierHistoryMutationResult = ApolloReactCommon.MutationResult<InsertSupplierHistoryMutation>;
export type InsertSupplierHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertSupplierHistoryMutation, InsertSupplierHistoryMutationVariables>;
export const UpdateSupplierHistoryDocument = gql`
    mutation updateSupplierHistory($id: String, $supplierHistory: supplier_history_set_input) {
  update_supplier_history(where: {id: {_eq: $id}}, _set: $supplierHistory) {
    affected_rows
  }
}
    `;
export type UpdateSupplierHistoryMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierHistoryMutation, UpdateSupplierHistoryMutationVariables>;

/**
 * __useUpdateSupplierHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierHistoryMutation, { data, loading, error }] = useUpdateSupplierHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      supplierHistory: // value for 'supplierHistory'
 *   },
 * });
 */
export function useUpdateSupplierHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierHistoryMutation, UpdateSupplierHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierHistoryMutation, UpdateSupplierHistoryMutationVariables>(UpdateSupplierHistoryDocument, baseOptions);
      }
export type UpdateSupplierHistoryMutationHookResult = ReturnType<typeof useUpdateSupplierHistoryMutation>;
export type UpdateSupplierHistoryMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierHistoryMutation>;
export type UpdateSupplierHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierHistoryMutation, UpdateSupplierHistoryMutationVariables>;
export const DeleteSupplierHistoryDocument = gql`
    mutation deleteSupplierHistory($id: String!) {
  delete_supplier_history(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteSupplierHistoryMutationFn = ApolloReactCommon.MutationFunction<DeleteSupplierHistoryMutation, DeleteSupplierHistoryMutationVariables>;

/**
 * __useDeleteSupplierHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierHistoryMutation, { data, loading, error }] = useDeleteSupplierHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSupplierHistoryMutation, DeleteSupplierHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSupplierHistoryMutation, DeleteSupplierHistoryMutationVariables>(DeleteSupplierHistoryDocument, baseOptions);
      }
export type DeleteSupplierHistoryMutationHookResult = ReturnType<typeof useDeleteSupplierHistoryMutation>;
export type DeleteSupplierHistoryMutationResult = ApolloReactCommon.MutationResult<DeleteSupplierHistoryMutation>;
export type DeleteSupplierHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSupplierHistoryMutation, DeleteSupplierHistoryMutationVariables>;
export const GetUserByAuthIdDocument = gql`
    query getUserByAuthId($authId: String!) {
  user(where: {auth0_id: {_eq: $authId}}) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUserByAuthIdQuery__
 *
 * To run a query within a React component, call `useGetUserByAuthIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByAuthIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByAuthIdQuery({
 *   variables: {
 *      authId: // value for 'authId'
 *   },
 * });
 */
export function useGetUserByAuthIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByAuthIdQuery, GetUserByAuthIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserByAuthIdQuery, GetUserByAuthIdQueryVariables>(GetUserByAuthIdDocument, baseOptions);
      }
export function useGetUserByAuthIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserByAuthIdQuery, GetUserByAuthIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserByAuthIdQuery, GetUserByAuthIdQueryVariables>(GetUserByAuthIdDocument, baseOptions);
        }
export type GetUserByAuthIdQueryHookResult = ReturnType<typeof useGetUserByAuthIdQuery>;
export type GetUserByAuthIdLazyQueryHookResult = ReturnType<typeof useGetUserByAuthIdLazyQuery>;
export type GetUserByAuthIdQueryResult = ApolloReactCommon.QueryResult<GetUserByAuthIdQuery, GetUserByAuthIdQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String, $user: user_set_input) {
  update_user(where: {id: {_eq: $id}}, _set: $user) {
    affected_rows
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: String, $company: company_set_input) {
  update_company(where: {id: {_eq: $id}}, _set: $company) {
    affected_rows
  }
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const RoomMessagesDocument = gql`
    subscription roomMessages($room: String) {
  message(where: {room: {_eq: $room}}, order_by: {created_at: asc}) {
    ...messageFields
  }
}
    ${MessageFieldsFragmentDoc}`;

/**
 * __useRoomMessagesSubscription__
 *
 * To run a query within a React component, call `useRoomMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomMessagesSubscription({
 *   variables: {
 *      room: // value for 'room'
 *   },
 * });
 */
export function useRoomMessagesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RoomMessagesSubscription, RoomMessagesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RoomMessagesSubscription, RoomMessagesSubscriptionVariables>(RoomMessagesDocument, baseOptions);
      }
export type RoomMessagesSubscriptionHookResult = ReturnType<typeof useRoomMessagesSubscription>;
export type RoomMessagesSubscriptionResult = ApolloReactCommon.SubscriptionResult<RoomMessagesSubscription>;
export const RoomMessageCountDocument = gql`
    subscription roomMessageCount($rooms: [String!]) {
  message_count(where: {room: {_in: $rooms}}) {
    count
    room
  }
}
    `;

/**
 * __useRoomMessageCountSubscription__
 *
 * To run a query within a React component, call `useRoomMessageCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomMessageCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomMessageCountSubscription({
 *   variables: {
 *      rooms: // value for 'rooms'
 *   },
 * });
 */
export function useRoomMessageCountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RoomMessageCountSubscription, RoomMessageCountSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RoomMessageCountSubscription, RoomMessageCountSubscriptionVariables>(RoomMessageCountDocument, baseOptions);
      }
export type RoomMessageCountSubscriptionHookResult = ReturnType<typeof useRoomMessageCountSubscription>;
export type RoomMessageCountSubscriptionResult = ApolloReactCommon.SubscriptionResult<RoomMessageCountSubscription>;
export const InsertMessageDocument = gql`
    mutation insertMessage($message: [message_insert_input!]!) {
  insert_message(objects: $message) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type InsertMessageMutationFn = ApolloReactCommon.MutationFunction<InsertMessageMutation, InsertMessageMutationVariables>;

/**
 * __useInsertMessageMutation__
 *
 * To run a mutation, you first call `useInsertMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMessageMutation, { data, loading, error }] = useInsertMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useInsertMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertMessageMutation, InsertMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertMessageMutation, InsertMessageMutationVariables>(InsertMessageDocument, baseOptions);
      }
export type InsertMessageMutationHookResult = ReturnType<typeof useInsertMessageMutation>;
export type InsertMessageMutationResult = ApolloReactCommon.MutationResult<InsertMessageMutation>;
export type InsertMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertMessageMutation, InsertMessageMutationVariables>;