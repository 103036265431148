import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import {
  Order,
  useInsertOrderMutation,
  useUpdateOrderMutation,
  useGetAllSuppliersQuery,
  InsertOrderMutation,
} from 'generated/graphql';
import { Store } from 'antd/lib/form/interface';
import { orderStatus } from '../constants';
import { useHistory } from 'react-router-dom';
import { ButtonType } from 'antd/lib/button/button';

const { Option } = Select;

interface OrderFormProps {
  afterClose?: () => void;
  order?: Order;
  defaultSupplierId?: string;
  defaultSupplierName?: string;
  buttonType?: ButtonType;
}

export const OrderForm = (props: OrderFormProps) => {
  const { afterClose, order, defaultSupplierId, defaultSupplierName, buttonType } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const [visible, setIsVisible] = useState(false);
  const { data } = useGetAllSuppliersQuery();
  const [insertOrderMutation] = useInsertOrderMutation();
  const [updateOrderMutation] = useUpdateOrderMutation();

  const onFinish = async (values: Store) => {
    let result;

    if (order && order.id) {
      result = await updateOrderMutation({
        variables: {
          id: order.id,
          order: values.order,
        },
      });
    } else {
      result = await insertOrderMutation({
        variables: {
          order: [values.order],
        },
      });
    }

    const { errors, data } = result;

    const dataInsert = (data as InsertOrderMutation).insert_order;
    if (dataInsert && dataInsert.returning && dataInsert.returning[0]) {
      history.push(`/dashboard/order/${dataInsert.returning[0].id}`);
    }

    if (!errors) {
      setIsVisible(false);
    } else {
      console.log('errors', errors);
    }
  };

  //from here https://ant.design/components/form/#Why-is-there-a-form-warning-when-used-in-Modal
  React.useEffect(() => {
    if (visible && order && order.id) {
      form.setFieldsValue({ order });
    } else if (visible) {
      //form defaults for new order
      form.setFieldsValue({
        order: {
          status: 0,
          supplier_id: defaultSupplierId,
          name: `${defaultSupplierName} ${new Date().toLocaleDateString('en-US')}`,
        },
      });
    }
  }, [form, order, visible]);

  const onCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  return (
    <React.Fragment>
      <Button
        type={buttonType ? buttonType : 'primary'}
        icon={order && order.id ? <EditOutlined /> : <PlusOutlined />}
        style={buttonType === 'link' ? { height: 24, padding: 0 } : {}}
        onClick={() => setIsVisible(true)}
      >
        {order && order.id ? 'Update order' : 'Create Order'}
      </Button>
      <Modal
        title={order && order.id ? 'Update order' : 'Create Order'}
        visible={visible}
        onOk={form.submit}
        onCancel={onCancel}
        forceRender
        afterClose={afterClose} // update results after modal close
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={form}
          name="add-order"
          onFinish={onFinish}
        >
          <Form.Item
            name={['order', 'name']}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item name={['order', 'supplier_id']} label="Supplier">
            <Select>
              {data?.supplier.map((supplier, i) => (
                <Option key={i} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['order', 'status']} label="Status">
            <Select>
              {orderStatus.map((type, i) => (
                <Option key={i} value={i}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
