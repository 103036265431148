import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Descriptions, Steps, Card, Col, Row, Spin, Tag, Table } from 'antd';
import { RouteConfigComponentProps } from 'react-router-config';
import {
  useGetSupplierByIdQuery,
  Supplier,
  useUpdateSupplierMutation,
  useInsertSupplierHistoryMutation,
  Supplier_History,
} from 'generated/graphql';
import { ErrorPage } from '../ErrorPage';
import { FileAddOutlined, EditOutlined } from '@ant-design/icons';
import { SupplierForm } from './components/SupplierForm';
import { SupplierNote } from './components/SupplierNote';
import { SupplierFileUpload } from './components/SupplierFileUpload';
import { SupplierHistory } from './components/SupplierHistory';
import { DeleteConfirm } from './components/DeleteConfirm';
import {
  supplierStatus,
  supplierType,
  getSupplierTagColors,
  supplierTags,
  supplierPipeline,
  updateSupplier,
} from './utils';
import './supplier.css';
import { useAuth0 } from 'components/auth/react-auth0-spa';
import { OrderForm } from 'components/dashboard/order/components/OrderForm';
import { orderStatus } from '../order/constants';
import { List as ScanList } from '../scan/list';

const { Step } = Steps;

export const View = (props: RouteConfigComponentProps<{ id: string }>) => {
  const { data, loading, error, refetch } = useGetSupplierByIdQuery({
    variables: {
      id: props.match.params.id,
    },
  });
  const { user } = useAuth0();
  const [updateSupplierMutation] = useUpdateSupplierMutation();
  const [insertSupplierHistoryMutation] = useInsertSupplierHistoryMutation();
  const [editHistory, setEditHistory] = useState<Supplier_History>();

  const contentList = {
    note: (
      <SupplierNote
        supplier_id={props.match.params.id}
        setEditHistory={setEditHistory}
        {...editHistory}
      />
    ),
    file: <SupplierFileUpload supplier_id={props.match.params.id} />,
  };

  const [activeTab, setActiveTab] = useState<keyof typeof contentList>('note');

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (!loading && (!data || data.supplier_by_pk === null)) {
    return <ErrorPage message={'Supplier does not exits'} />;
  }

  if (loading || !data || !data.supplier_by_pk) {
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  const supplier = data.supplier_by_pk as Supplier;

  const onPipelineStepChange = async (step: number) => {
    const { errors } = await updateSupplier({
      supplier,
      updatedSupplier: {
        pipeline: step,
      },
      userId: user?.sub,
      updateSupplierMutation,
      insertSupplierHistoryMutation,
    });

    if (errors) {
      console.log('errors', errors);
    }
  };

  return (
    <Spin spinning={loading}>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={supplier?.name}
        // @ts-ignore
        tags={<Tag color="blue">{supplierStatus[supplier?.status as number]}</Tag>}
        subTitle={supplierType[supplier?.type as number]}
        extra={[
          <SupplierForm
            afterClose={refetch}
            supplier={supplier}
            modalTitle={`Update Supplier ${supplier?.name}`}
            buttonTitle="Update Supplier"
            key={2}
          />,
          <DeleteConfirm id={supplier.id} type="supplier" key={supplier.id} />,
        ]}
      >
        <Steps
          size="small"
          current={supplier.pipeline as number}
          type="navigation"
          onChange={onPipelineStepChange}
        >
          {supplierPipeline.map((name, id) => (
            <Step key={id} title={name} style={{ fontSize: 8 }} />
          ))}
        </Steps>
      </PageHeader>
      <Row gutter={12}>
        <Col span={10}>
          <Card type="inner" bodyStyle={{ padding: 0 }} title="Supplier Details">
            <Descriptions size="small" column={1} bordered>
              <Descriptions.Item label="Phone">{supplier?.phone}</Descriptions.Item>
              <Descriptions.Item label="Email">{supplier?.email}</Descriptions.Item>
              <Descriptions.Item label="Site">
                {supplier.site ? (
                  <a href={supplier.site} target="_blank" rel="noopener noreferrer">
                    {supplier.site}
                  </a>
                ) : (
                  ''
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Tags">
                {supplier.tags &&
                  supplier.tags.map((t: number) => (
                    <Tag key={t} color={getSupplierTagColors(t)}>
                      {supplierTags[t]}
                    </Tag>
                  ))}
              </Descriptions.Item>
              <Descriptions.Item label="Created at">
                {new Date(supplier?.created_at).toLocaleDateString('en-US')}
              </Descriptions.Item>
              <Descriptions.Item label="Description">{supplier?.description}</Descriptions.Item>
            </Descriptions>
          </Card>
          <Card
            type="inner"
            style={{ marginTop: 20 }}
            bodyStyle={{ padding: 0 }}
            title="Orders"
            extra={
              <OrderForm
                buttonType="link"
                defaultSupplierId={supplier.id}
                defaultSupplierName={supplier.name}
              />
            }
          >
            <Table
              style={{ borderTop: '1px solid #f0f0f0' }}
              dataSource={supplier?.orders}
              pagination={false}
              size="small"
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  // eslint-disable-next-line
                  render: (data, order) => <Link to={`/dashboard/order/${order.id}`}>{data}</Link>,
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  render: (data) => orderStatus[data],
                },
                {
                  title: 'Total',
                  dataIndex: ['data', 'total'],
                  render: (data) => `$${data}`,
                },
                {
                  title: 'Created at',
                  dataIndex: 'created_at',
                  render: (date) => new Date(date).toLocaleDateString('en-US'),
                },
              ]}
              rowKey="id"
            />
          </Card>
          <ScanList supplierId={supplier.id} xpath={supplier.data?.xpath} />
        </Col>
        <Col span={14}>
          <Card
            type="inner"
            tabList={[
              {
                key: 'note',
                tab: (
                  <span>
                    <EditOutlined /> Take Notes
                  </span>
                ),
              },
              {
                key: 'file',
                tab: (
                  <span>
                    <FileAddOutlined />
                    Upload Files
                  </span>
                ),
              },
            ]}
            activeTabKey={activeTab}
            //@ts-ignore
            onTabChange={setActiveTab}
          >
            {contentList[activeTab]}
          </Card>
          <SupplierHistory
            supplier_history={data.supplier_history}
            setEditHistory={setEditHistory}
          />
        </Col>
      </Row>
    </Spin>
  );
};
