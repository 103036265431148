/* eslint react/display-name: 0 */
import React from 'react';
import { Spin, Table, Card, Button, Dropdown, Menu, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  useGetScanBySupplierIdQuery,
  Scan,
  useInsertScanMutation,
  useUpdateScanMutation,
} from 'generated/graphql';
import { ErrorPage } from '../ErrorPage';
import { scanStatus, scanType } from './constants';
import { ScanForm } from './components/ScanForm';
import { SupplierXpathForm, SupplierXpathFormProps } from './components/SupplierXpathForm';
import useFetch from 'use-http/dist';
import { ClickParam } from 'antd/es/menu';

const scanServer = process.env.REACT_APP_CRAWLER_URL ? process.env.REACT_APP_CRAWLER_URL : '';

export const List = ({ supplierId, xpath }: SupplierXpathFormProps) => {
  const { loading, error, data } = useGetScanBySupplierIdQuery({
    variables: {
      id: supplierId,
    },
  });
  const [insertScanMutation] = useInsertScanMutation();
  const [updateScanMutation] = useUpdateScanMutation();

  // @ts-ignore
  const { post, response, loading: fetchLoading } = useFetch(scanServer, {
    cachePolicy: 'no-cache',
  });
  const startScan = async (e: ClickParam) => {
    //type of scan 0 - crawler, 1 - xml scan
    const { data, errors } = await insertScanMutation({
      refetchQueries: ['getScanBySupplierId'],
      variables: {
        data: [
          {
            supplier_id: supplierId,
            status: 0,
            type: parseInt(e.key),
          },
        ],
      },
    });

    if (errors) {
      console.error('insertScanMutation error', errors);
    }
    const scanId = data?.insert_scan?.returning[0].id;

    const result = await post('/startScan', { scanId, type: e.key });

    if (response.ok && result && result.processId) {
      const { errors } = await updateScanMutation({
        refetchQueries: ['getScanBySupplierId'],
        variables: {
          id: scanId,
          data: {
            process_id: result.processId,
          },
        },
      });

      if (errors) {
        console.error('updateScanMutation error', errors);
      }

      message.success(`Scan ${scanId}:${result.processId} successfully started`);
    } else {
      message.error('Can not start scan');
    }
  };

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  const scan = data?.scan;

  return (
    <Card
      type="inner"
      style={{ marginTop: 20 }}
      bodyStyle={{ padding: 0 }}
      title="Scans"
      extra={
        <>
          <SupplierXpathForm supplierId={supplierId} xpath={xpath} />
          <Dropdown
            overlay={
              <Menu onClick={startScan}>
                <Menu.Item key="0">CSV scan</Menu.Item>
                <Menu.Item key="1">XML scan</Menu.Item>
              </Menu>
            }
          >
            <Button
              disabled={!xpath}
              loading={fetchLoading}
              style={{ height: 24, padding: 0, border: 0, background: 'inherit' }}
            >
              Start scan <DownOutlined />
            </Button>
          </Dropdown>
        </>
      }
    >
      <Table
        style={{ borderTop: '1px solid #f0f0f0' }}
        dataSource={scan}
        pagination={false}
        size="small"
        columns={[
          {
            title: 'Scan id',
            dataIndex: 'id',
            render: (data: number, row: Scan) => <ScanForm supplierId={supplierId} scan={row} />,
          },
          {
            title: 'Scan type',
            dataIndex: 'type',
            render: (data) => scanType[data],
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (data) => scanStatus[data],
          },
          {
            title: 'Created at',
            dataIndex: 'created_at',
            render: (date) => new Date(date).toLocaleDateString('en-US'),
          },
        ]}
        rowKey="id"
      />
    </Card>
  );
};
