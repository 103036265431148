import Papa from 'papaparse';
import { message } from 'antd';

export const parseCsv = async (file: File, transformHeader = false): Promise<[]> => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      //convert headers to camelCase
      transformHeader: transformHeader
        ? (header: string) =>
            header
              .toLowerCase()
              .replace(/[^a-zA-Z0-9()]+(.)/g, (m, chr) => chr.toUpperCase())
              .replace(/\(|\)/g, '')
        : (header: string) => header,
      skipEmptyLines: true,
      complete: (results: { data: []; errors: Error[] }) => {
        const { data: csvDataParsed, errors } = results;

        resolve(csvDataParsed);

        if (errors.length) {
          console.error(errors);
          message.error(`Can not parse CSV file`);
        }
      },
    });
  });
};
