import React, { useState, Dispatch, SetStateAction } from 'react';
import { Supplier_History } from 'generated/graphql';
import {
  FileAddOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Card, Timeline, Button } from 'antd';
import { SupplierNote } from './SupplierNote';
import { DeleteConfirm } from './DeleteConfirm';
import { FileData } from './SupplierFileUpload';
import { supplierStatus, supplierPipeline } from '../utils';

interface SupplierHistoryProps {
  supplier_history: Pick<Supplier_History, 'id' | 'text' | 'type' | 'created_at' | 'user_id'>[];
  setEditHistory: Dispatch<SetStateAction<Supplier_History | undefined>>;
}
export const SupplierHistory = ({ supplier_history, setEditHistory }: SupplierHistoryProps) => {
  const [fileDownloading, setFileDownloading] = useState<string>();

  const downloadFile = (item: FileData) => {
    setFileDownloading(item.id);
    fetch(`${item.href}/contents`, {
      headers: {
        Authorization: process.env.REACT_APP_KLOUDLESS_AUTH
          ? process.env.REACT_APP_KLOUDLESS_AUTH
          : '',
      },
    }).then((response) => {
      if (response.status !== 200) {
        console.error('response', response);
        setFileDownloading('');
        return;
      }
      return response.blob().then((b) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', item.name);
        a.click();
        setFileDownloading('');
      });
    });
  };

  const mapIconToType = (type: number) => {
    //Note added
    if (type === 1) {
      return <EditOutlined style={{ fontSize: '18px' }} />;
    }
    //File uploaded
    if (type === 2) {
      return <FileAddOutlined style={{ fontSize: '18px' }} />;
    }
    //Status changed
    if (type === 3) {
      return <IssuesCloseOutlined style={{ fontSize: '18px' }} />;
    }
  };

  const mapContentToType = (item: Supplier_History) => {
    const date = new Date(item.created_at).toLocaleString('en-US');
    //Note added
    if (item.type === 1) {
      return (
        <Card size="small" key={item.id}>
          <SupplierNote {...item} readOnly={true} />
          <ul className="ant-card-actions">
            <li>{item?.user?.name}</li>
            <li style={{ marginLeft: 20 }}>{date}</li>
            <li style={{ float: 'right' }}>
              <DeleteConfirm id={item.id} type="supplierHistory" size="small" />
            </li>
            <li style={{ float: 'right' }}>
              <Button type="primary" onClick={() => setEditHistory(item)} size="small" title="Edir">
                <EditOutlined />
              </Button>
            </li>
          </ul>
        </Card>
      );
    }
    //File uploaded
    if (item.type === 2) {
      if (!item.text || !item.text.name) {
        return <div>problem with parsing data</div>;
      }

      const fileData: FileData = item.text;

      return (
        <Card size="small" key={item.id}>
          File {fileData.name} was uploaded
          <ul className="ant-card-actions">
            <li>{item?.user?.name}</li>
            <li style={{ marginLeft: 20 }}>{date}</li>
            <li style={{ float: 'right' }}>
              <DeleteConfirm id={item.id} type="supplierHistory" size="small" />
            </li>
            <li style={{ float: 'right', marginRight: 5 }}>
              <Button
                loading={fileDownloading === fileData.id}
                type="primary"
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => downloadFile(fileData)}
              >
                {fileData.name}
              </Button>
            </li>
          </ul>
        </Card>
      );
    }
    //Status changed
    if (item.type === 3) {
      return (
        <Card size="small" key={item.id}>
          {item.text && item.text.status !== undefined && (
            <div>
              Status change:{' '}
              {item.text.statusOld !== undefined && `${supplierStatus[item.text.statusOld]} > `}
              {supplierStatus[item.text.status]}
            </div>
          )}

          {item.text && item.text.pipeline !== undefined && (
            <div>
              Pipeline change:{' '}
              {item.text.pipelineOld !== undefined &&
                `${supplierPipeline[item.text.pipelineOld]} > `}
              {supplierPipeline[item.text.pipeline]}
            </div>
          )}

          {item.text && typeof item.text === 'string' && <div>{item.text}</div>}

          <ul className="ant-card-actions">
            <li>{item?.user?.name}</li>
            <li style={{ marginLeft: 20 }}>{date}</li>
          </ul>
        </Card>
      );
    }
  };

  return (
    <Card
      type="inner"
      title="Supplier history"
      className="supplierHistory"
      style={{ marginTop: 25 }}
    >
      <Timeline mode="left" reverse={true}>
        {supplier_history?.map((item) => (
          <Timeline.Item key={item.id} dot={mapIconToType(item.type)}>
            {mapContentToType(item as Supplier_History)}
          </Timeline.Item>
        ))}
      </Timeline>
    </Card>
  );
};
