import React, { useState } from 'react';
import { Tabs, Layout, Spin, Row, Col } from 'antd';
import { useGetUserByAuthIdQuery, User } from 'generated/graphql';
import { useAuth0 } from '../../auth/react-auth0-spa';
import { ErrorPage } from '../ErrorPage';
import { UserForm } from './components/UserForm';
import { CompanyForm } from './components/CompanyForm';

const { TabPane } = Tabs;

export const View = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const { user } = useAuth0();
  const { data, loading, error } = useGetUserByAuthIdQuery({
    variables: {
      authId: user?.sub as string,
    },
  });

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (!loading && (!data || data.user === null)) {
    return <ErrorPage message={'User does not exits'} />;
  }

  if (loading || !data || !data.user) {
    return (
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  const dbUser = data.user[0] as User;

  const tabs = [{ title: 'Profile', content: <UserForm user={dbUser} />, key: '1' }];
  if (dbUser.company) {
    tabs.push({ title: 'Company', content: <CompanyForm company={dbUser.company} />, key: '2' });
  }

  return (
    <Layout.Content style={{ padding: 24, minHeight: 280, background: '#fff' }}>
      <Tabs onChange={setActiveTab} activeKey={activeTab}>
        {tabs.map((pane) => (
          <TabPane tab={pane.title} key={pane.key}>
            <Row>
              <Col span={12} offset={6}>
                {pane.content}
              </Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </Layout.Content>
  );
};
