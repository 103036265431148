import React, { useState, createRef, useEffect } from 'react';
import { Comment, Form, Button, List, Input, Avatar } from 'antd';
import {
  useInsertMessageMutation,
  useGetUserByAuthIdQuery,
  MessageFieldsFragment,
  useRoomMessagesSubscription,
} from 'generated/graphql';
import { useAuth0 } from '../../auth/react-auth0-spa';
import './styles.css';

const { TextArea } = Input;

export const ChatRoom = ({ id }: { id: string }) => {
  const room = `product-${id}`;
  const { user } = useAuth0();
  const { data: dbUser } = useGetUserByAuthIdQuery({
    variables: {
      authId: user?.sub as string,
    },
  });
  const dbUserName = dbUser?.user[0].name;

  const { data, loading } = useRoomMessagesSubscription({
    variables: { room },
  });

  const [insertMessageMutation] = useInsertMessageMutation();

  const message = data?.message;

  const sendMessage = async (text: string) => {
    const { errors } = await insertMessageMutation({
      variables: {
        message: [{ room, text, user_id: user?.sub }], // value for 'message'
      },
    });

    if (errors) {
      console.log('sendMessage errors', errors);
    }
  };

  return (
    <div>
      {message && message?.length > 0 && <CommentList message={message} dbUserName={dbUserName} />}
      <Comment
        avatar={<Avatar src={user?.picture} alt={dbUserName} />}
        content={<Editor onSubmit={sendMessage} loading={loading} />}
      />
    </div>
  );
};

const CommentList = ({
  message,
  dbUserName,
}: {
  message: MessageFieldsFragment[];
  dbUserName?: string;
}) => {
  const listRef = createRef<HTMLDivElement>();

  useEffect(() => {
    listRef.current?.scrollIntoView(false);
  });

  return (
    <List
      style={{ height: 300, overflow: 'auto' }}
      dataSource={message}
      itemLayout="horizontal"
      footer={<div ref={listRef} />}
      size="small"
      renderItem={(props: MessageFieldsFragment) => (
        <Comment
          className={`comment ${props.user.name === dbUserName ? 'myComment' : ''}`}
          author={props.user && props.user.name}
          datetime={new Date(props.created_at).toLocaleString('en-US')}
          content={props.text}
        />
      )}
    />
  );
};

const Editor = ({ onSubmit, loading }: { onSubmit: (text: string) => void; loading: boolean }) => {
  const [text, setText] = useState<string>('');
  return (
    <div>
      <Form.Item style={{ marginBottom: 5 }}>
        <TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 5 }}>
        <Button
          htmlType="submit"
          loading={loading}
          onClick={() => {
            onSubmit(text);
            setText('');
          }}
          type="primary"
        >
          Add Comment
        </Button>
      </Form.Item>
    </div>
  );
};
